import { FC, useCallback } from "react";

import { Button, P, Ui } from "common/components/atoms";
import { ArrowRightIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useSubscriptionType from "../../../../hooks/useSubscriptionType";
import { CONTACT_US_LINK } from "../../../../utils/constants";
import { FEATURES } from "../../../types";
import { useFeatures } from "../../../useFeatures";
import SubscriptionManagerInfo from "../../SubscriptionManagerInfo";
import classes from "../templates.module.scss";

const t = createTranslation(TranslationNS.common, "noSubscription.bottomBanner");

type Props = {
  bannerImage: string;
  title?: string;
};
const BottomBanner: FC<Props> = ({ bannerImage, title }) => {
  const subscription = useStoreState((state) => state.company.subscriptionContactInfo);
  const user = useStoreState((state) => state.account.user);

  const showContactManager = !!subscription?.isStripeSubscription && user?.email !== subscription?.buyerEmail;

  const { hasSubscriptionType } = useSubscriptionType();

  const handleScroll = useCallback(() => {
    document.getElementById("stripeManagePlan")?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, []);

  const { hasFullAccess } = useFeatures(FEATURES.companyInformation);

  return (
    <div className={classes.bottomBanner}>
      <div className={"w-50"}>
        <img src={bannerImage} alt="pools" />
      </div>

      <div className={"w-50 pt-2 pb-3"}>
        <div>
          <Ui.m color="foregroundHigh" style={{ fontWeight: 500 }}>
            {!title && (hasSubscriptionType ? t("upgrade") : t("subscribe"))}
            {!!title && title}
          </Ui.m>
          {hasFullAccess && (
            <Button className="mt-2" size="s" style={{ width: 140 }} onClick={handleScroll}>
              {t("viewDetails")}
            </Button>
          )}
        </div>
        {!showContactManager ? (
          <div className={!hasFullAccess ? "mt-12" : "mt-8"}>
            <P.s color="foregroundHigh">{t("curious")}</P.s>
            <Button
              as="a"
              variant="tertiary"
              className="mt-2"
              size="s"
              iconRight={<ArrowRightIcon />}
              target="_blank"
              rel="noreferrer"
              href={CONTACT_US_LINK}
            >
              {t("contactUs")}
            </Button>
          </div>
        ) : (
          <div className="mt-6">
            <Ui.m color="foregroundHigh" className="mb-1" style={{ fontWeight: 500 }}>
              {t("toUpgrade")}
            </Ui.m>
            <SubscriptionManagerInfo
              buyerAvatarFilePath={subscription?.buyerAvatarFilePath}
              buyerFirstName={subscription?.buyerFirstName}
              buyerEmail={subscription?.buyerEmail}
              buyerInitials={subscription?.buyerInitials}
              buyerLastName={subscription?.buyerLastName}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomBanner;
