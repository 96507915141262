import React, { FC, useEffect } from "react";

import { STRIPE_PRICING_TABLE_ID, STRIPE_PUBLISHABLE_KEY } from "common/utils/constants";
import { useStoreState } from "store/store";

// This is component to display embedded pricing table
// All data is getting from the stripe side
// Important part for this component is to pass current account email (prefilling feature)
// And client-reference-id - in our case this is the way how we connect companies with stripe subscriptions

const StripePricingTable: FC<{ companyId: string; tableID?: string }> = ({
  companyId,
  tableID = STRIPE_PRICING_TABLE_ID,
}) => {
  const account = useStoreState((state) => state.account);

  useEffect(() => {
    // adding pricing table script that will display remote data from the stripe API
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <stripe-pricing-table
      pricing-table-id={tableID}
      // TODO: for tests in dev2, remove after updates will be in staging
      publishable-key={
        STRIPE_PUBLISHABLE_KEY ||
        "pk_test_51IluDbBNxcVSl80zBpjmiMfzEiWlJiq5WuNaJVVM4M3wX4RV0RnIYDF9q50kQxutCOPLKGY1nndbmYQbA7WhuAGG003WsIoGXP"
      }
      client-reference-id={companyId}
      customer-email={account?.user?.email}
    ></stripe-pricing-table>
  );
};
export default StripePricingTable;
