import React, { EventHandler, SyntheticEvent } from "react";

import { P, Ui } from "common/components/atoms";
import { WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";

import classes from "./aro-toast-warning.module.scss";

// This function will check current step to be enabled
export function checkAroEnableStep(isCheckPassed: Boolean, cb: EventHandler<any>) {
  return (e: SyntheticEvent<Element, Event>) => {
    if (!isCheckPassed) {
      notify("", true, "info", 5000, true, "top-center", classes["container"], <AroToastWarning />);
    } else {
      cb(e);
    }
  };
}

const AroToastWarning = () => {
  return (
    <div className="d-flex">
      <WarningIcon fontSize={24} className="me-2 flex-shrink-0" color={scssVariables.information700} />

      <div>
        <Ui.m bold>Vennligst fullfør de forrige trinnene for å fortsette.</Ui.m>
        <P.s>
          Du må fullføre de tidligere trinnene før du kan fortsette. Vennligst gjennomgå og ferdigstill de tidligere
          seksjonene.
        </P.s>
      </div>
    </div>
  );
};

export default AroToastWarning;
