import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import { allPass, defaultTo, equals, omit, pick, values } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { CompanyFeatures } from "common/access-control/types";
import { Button } from "common/components/atoms";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "aro.main.footer");

type PT = {
  className?: string;
};

const AroFooter: FC<PT> = ({ className }) => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();
  const aroDetails = useStoreState((store) => store.aroModel.aroDetails);
  const companies = useStoreState((store) => store?.account?.user?.companies);

  const currentCompany = useMemo(
    () => companies?.find((item) => Number(item.id) === Number(companyId)),
    [companies, companyId]
  );

  const submit = () => {
    // checking if ARO status hasAccess for current company for user companies list
    // note: potentially need to refresh current user API endpoint after each step updates
    const aroAccessibleStatus = currentCompany?.accessibleFeatures?.find((el) => el.id === CompanyFeatures.ARO);

    if (aroAccessibleStatus?.hasAccess) {
      navigate(getPath(["aro", "sendDetailsToAltinn"], { companyId }));
    } else {
      navigate(getPath(["aro", "buyAroProduct"], { companyId }));
    }
  };

  const allStepsCompleted = allPass([
    (input) => {
      return (
        values(omit(["previousShareholdersStatus", "currentShareholdersStatus"], input)).every(
          equals(AroSectionStatuses.Utfylt)
        ) &&
        values(pick(["previousShareholdersStatus", "currentShareholdersStatus"], input)).every(
          (item) => equals(item, AroSectionStatuses.Utfylt) || equals(item, AroSectionStatuses.Forhåndsutfylt)
        )
      );
    },
  ])(
    pick(
      [
        "companyInformationStatus",
        "altinnConnectionStatus",
        "previousShareholdersStatus",
        "transactionsStatus",
        "currentShareholdersStatus",
        "dividendsStatus",
      ],
      defaultTo({}, aroDetails)
    )
  );

  return (
    <div className={cn("d-flex w-100 gap-3", className)}>
      <Button isDisabled={!allStepsCompleted} onClick={submit}>
        {t("submit")}
      </Button>
      {/*/!* TODO what on cancel ? *!/*/}
      {/*<Button variant="secondary" isDisabled={isSubmitting} onClick={() => {}}>*/}
      {/*  {t("cancel")}*/}
      {/*</Button>*/}
    </div>
  );
};

export default AroFooter;
