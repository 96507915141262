import { FC, memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../../service/aro-service";
import { CheckTransaction, PostFormTransaction, TransactionsMode } from "../types";
import DependentTransactions from "./dependent-transactions";
import Form from "./form";
import classes from "./styles.module.scss";
import useEditTransactionsColumns from "./use-edit-transactions-columns";
import useEditTransactionsForm from "./use-edit-transactions-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.editTransactionsTable");

type PT = {
  onClose(): void;
};

const EditTransactionsTable: FC<PT> = memo(({ onClose }) => {
  const { companyId } = useParams<{ companyId?: string }>();

  const [dependentTransactions, setDependentTransactions] = useState<CheckTransaction[]>([]);

  const closeDependentTransactionsModal = useCallback(() => {
    setDependentTransactions([]);
  }, []);

  const year = useStoreState((state) => state.aroModel?.aroDetails?.year || 2000);
  const transactions = useStoreState((state) => state.aroModel?.formTransactions?.transactions);
  const transactionCategories = useStoreState((state) => state.aroModel?.formTransactions?.transactionCategories);
  const issueSharesTypes = useStoreState((state) => state.aroModel?.formTransactions?.issueShareTypes);
  const buySellTypes = useStoreState((state) => state.aroModel?.formTransactions?.buySellTypes);
  const shareClasses = useStoreState((state) => state.aroModel?.formTransactions?.shareClasses);
  const stakeholders = useStoreState((state) => state.aroModel?.formTransactions?.stakeholders);
  const getFormTransactions = useStoreActions((actions) => actions.aroModel.getFormTransactionsThunk);
  const getTransactions = useStoreActions((actions) => actions.aroModel.getTransactionsThunk);
  const postTransactions = useStoreActions((actions) => actions.aroModel.postTransactionsThunk);
  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);
  const getShareholders = useStoreActions((actions) => actions.aroModel.getShareholdersAtYearEndThunk);

  useEffect(() => {
    if (companyId) {
      getFormTransactions(companyId);
    }
  }, [companyId, getFormTransactions]);

  const refetchData = useCallback(() => {
    return getFormTransactions(companyId || "0");
  }, [companyId, getFormTransactions]);

  const { validationSchema, initialValues } = useEditTransactionsForm(transactions || [], shareClasses || []);

  const { columns } = useEditTransactionsColumns(
    refetchData,
    year,
    shareClasses,
    transactionCategories,
    issueSharesTypes,
    buySellTypes,
    stakeholders,
    1 // NOK
  );

  const handleSubmit = useCallback<FormikConfig<PostFormTransaction[]>["onSubmit"]>(
    async (values) => {
      try {
        if (companyId) {
          const existedIds = values
            .filter((transaction) => transaction.transactionId)
            .map((transaction) => transaction.transactionId);

          const transactionIdsToDelete = transactions
            ?.filter((transaction) => !existedIds.includes(transaction.transactionId))
            .map((transaction) => transaction.transactionId) as number[];

          const result = await postTransactions({
            companyId: companyId,
            transactions: values.map((transaction) => ({
              ...transaction,
              numberOfShares: Number(transaction.numberOfShares),
              sharePrice: Number(transaction.sharePrice),
              fromStakeholderId: transaction.fromStakeholderId === -1 ? null : transaction.fromStakeholderId,
            })),
            transactionIdsToDelete: transactionIdsToDelete,
          });

          if (!result.hasErrorsAfterEdit) {
            notify(t("successToastTransactions"), true, "success");
            getShareholders(companyId);
            const result = await getTransactions(companyId);

            if (result?.transactions.length === 0) {
              await aroService.postTransactionsDone({ companyId, transactionsDone: true });
              await aroService.postTransactionsMode({ companyId, transactionsMode: TransactionsMode.NoTransactions });
              notify(t("successToast"), true, "success");
            }

            await getAroDetails(companyId);
            await getTransactions(companyId);
            onClose();
          }

          if (result.hasErrorsAfterEdit) {
            setDependentTransactions(result.failedTransactions);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, getAroDetails, getShareholders, getTransactions, onClose, postTransactions, transactions]
  );

  return (
    <WizardLayout className={classes.wizard}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form columns={columns} onClose={onClose} />
      </Formik>
      {dependentTransactions.length > 0 && (
        <DependentTransactions transactions={dependentTransactions} onClose={closeDependentTransactionsModal} />
      )}
    </WizardLayout>
  );
});

export default EditTransactionsTable;
