import { FC } from "react";

import { Button, NewAvatar, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { CheckIcon, EditIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

const TableLoading: FC = () => (
  <tr>
    <td>
      <div className="d-flex align-items-center">
        <NewAvatar size="s" />

        <div className="ms-2">
          <div className="d-flex align-items-center">
            <Ui.s bold className="me-1">
              <Skeleton isLoading={true}>Shareholder Name</Skeleton>
            </Ui.s>
          </div>
        </div>
      </div>
    </td>
    <td>
      <Ui.s>
        <Skeleton isLoading={true}>123 456 678 901</Skeleton>
      </Ui.s>
    </td>
    )
    <td>
      <Ui.s>
        <Skeleton isLoading={true}>Share class name</Skeleton>
      </Ui.s>
    </td>
    <td className="text-end">
      <Ui.s>
        <Skeleton isLoading={true}>123 456</Skeleton>
      </Ui.s>
    </td>
    <td>
      <div className="d-flex justify-content-center align-items-center ps-2">
        <Skeleton isLoading={true}>
          <CheckIcon fontSize={24} color={scssVariables.positive500} />
        </Skeleton>
        <Skeleton isLoading={true}>
          <Button isOnlyIcon size="s" variant="tertiary" className="ms-auto">
            <EditIcon />
          </Button>
        </Skeleton>
      </div>
    </td>
  </tr>
);

export default TableLoading;
