import { FC, memo } from "react";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { ShareClass } from "../../../types";

type PT = {
  shareClass: ShareClass;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body.shareClassesTable");

const ShareholdersTableBody: FC<PT> = memo(({ shareClass }) => {
  const fNumber = useFormatNumbers();

  return (
    <tbody>
      <tr>
        <td>
          <Ui.xs>{t("row1")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.previousSharesCapital, "value")}</Ui.xs>
        </td>
        <td className="text-end">
          <Ui.xs>{fNumber(shareClass.sharesCapital, "value")}</Ui.xs>
        </td>
      </tr>

      <tr>
        <td>
          <Ui.xs>{t("row2")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.previousNumberOfShares, "amount")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.numberOfShares, "amount")}</Ui.xs>
        </td>
      </tr>

      <tr>
        <td>
          <Ui.xs>{t("row3")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.previousSharesCapitalPaid, "value")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.sharesCapitalPaid, "value")}</Ui.xs>
        </td>
      </tr>

      <tr>
        <td>
          <Ui.xs>{t("row4")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.previousSharesPremium, "value")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.sharesPremium, "value")}</Ui.xs>
        </td>
      </tr>

      <tr>
        <td>
          <Ui.xs>{t("row5")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.previousNominalValue, "value")}</Ui.xs>
        </td>
        <td>
          <Ui.xs className="text-end">{fNumber(shareClass.nominalValue, "value")}</Ui.xs>
        </td>
      </tr>
    </tbody>
  );
});

export default ShareholdersTableBody;
