import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import MainLayout from "common/layout/MainLayout/MainLayout";
import { Languages } from "translation";

import AccessMiddlewareAro from "../../app/middleware/AccessMiddleware/AccessMiddlewareAro";
import BuyAroProduct from "./pages/buy-aro-product/buy-aro-product";
import AroOnboarding from "./pages/onboard/AroOnboarding";
import AroPricingTable from "./pages/pricing/aro-pricing-table";
import ReportPage from "./pages/report/main";
import SendDetailsToAltinn from "./pages/send-details-to-altinn/send-details-to-altinn";

const AroRouter: FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== "no") {
      i18n.changeLanguage(Languages.no);
    }

    return () => {
      i18n.changeLanguage(Languages.en);
    };
  }, [i18n, i18n.language]);

  return (
    <MainLayout>
      <Routes>
        <Route path={ROUTER_V2.aro.onboard} element={<AroOnboarding />} />
        <Route element={<AccessMiddlewareAro />}>
          <Route path={ROUTER_V2.aro.report} element={<ReportPage />} />
          <Route path={ROUTER_V2.aro.pricing} element={<AroPricingTable />} />
          <Route path={ROUTER_V2.aro.buyAroProduct} element={<BuyAroProduct />} />
          <Route path={ROUTER_V2.aro.sendDetailsToAltinn} element={<SendDetailsToAltinn />} />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default AroRouter;
