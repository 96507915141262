import { ChangeEventHandler, FC, useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { Button, ChecksGroup, H, P, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../../service/aro-service";
import classes from "../styles.module.scss";
import { TransactionsMode } from "../types";

type PT = {
  handleDeleteTransactions(): void;
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.checkboxes");

const RadiobuttonsSection: FC<PT> = ({ handleDeleteTransactions, className }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const [isDeleteTransactionsModalOpen, setIsDeleteTransactionsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const year = useStoreState((state) => state.aroModel.aroDetails?.year || 2000);
  const isTransactionsDone = useStoreState((state) => state.aroModel.transactions?.allTransactionsDone);
  const transactions = useStoreState((state) => state.aroModel.transactions?.transactions);
  const transactionsMode = useStoreState((state) => state.aroModel.transactions?.transactionsMode);
  const isLoading = useStoreState((state) => state.aroModel.isAroDetailsLoading);
  const getTransactions = useStoreActions((actions) => actions.aroModel.getTransactionsThunk);
  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);
  const getShareholdersAtYearEnd = useStoreActions((actions) => actions.aroModel.getShareholdersAtYearEndThunk);

  const handleRadioCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
    async ({ target }) => {
      if (companyId && target.checked) {
        setIsSubmitting(true);
        if ((Number(target.name) as unknown as TransactionsMode) === TransactionsMode.NoTransactions) {
          if (transactions?.length) {
            setIsDeleteTransactionsModalOpen(true);
          } else {
            await aroService.postTransactionsMode({ companyId, transactionsMode: TransactionsMode.NoTransactions });
            await aroService.postTransactionsDone({ companyId, transactionsDone: true });
            await getTransactions(companyId);
            await getAroDetails(companyId);
            await getShareholdersAtYearEnd(companyId);
          }
        }

        if ((Number(target.name) as unknown as TransactionsMode) === TransactionsMode.ImportTransactions) {
          await aroService.postTransactionsMode({ companyId, transactionsMode: TransactionsMode.ImportTransactions });
          if (isTransactionsDone) {
            await aroService.postTransactionsDone({ companyId, transactionsDone: false });
          }
          await getTransactions(companyId);
          await getAroDetails(companyId);
        }
      }
      setIsSubmitting(false);
    },
    [companyId, getAroDetails, getShareholdersAtYearEnd, getTransactions, isTransactionsDone, transactions?.length]
  );

  const handleSubmit = () => {
    setIsDeleteTransactionsModalOpen(false);
    handleDeleteTransactions();
  };

  return (
    <div className={className}>
      <div className={cn("py-2 px-3 rounded-3 mb-2", classes.backgroundElement)}>
        <H.xxs className="mb-1">
          {t.el("title", {
            components: [<Skeleton key={0} isLoading={isLoading} />],
            values: { year },
          })}
        </H.xxs>
        <P.s color="foregroundMedium" className="mb-2">
          {t("paragraph")}
        </P.s>
        <ChecksGroup>
          <ChecksGroup.Check
            type="radio"
            name={String(TransactionsMode.NoTransactions)}
            checked={transactionsMode === TransactionsMode.NoTransactions}
            onChange={handleRadioCheck}
            label={t("option1.title")}
            description={t("option1.description")}
          />
          <ChecksGroup.Check
            type="radio"
            name={String(TransactionsMode.ImportTransactions)}
            checked={transactionsMode === TransactionsMode.ImportTransactions}
            onChange={handleRadioCheck}
            label={t("option2.title")}
            description={t.el("option2.description", {
              components: [<Skeleton key={0} isLoading={isLoading} />],
              values: { dateFrom: `01.01.${year}`, dateTo: `31.12.${year}` },
            })}
          />
        </ChecksGroup>
      </div>
      {transactionsMode === TransactionsMode.NoTransactions && (
        <div className={cn("py-2 px-3 rounded-3", classes.backgroundPositive)}>
          <Ui.m bold>{t("positivePlate.title")}</Ui.m>
          <P.s>
            {t.el("positivePlate.paragraph", {
              components: [<Skeleton key={0} isLoading={isLoading} />],
              values: { year },
            })}
          </P.s>
        </div>
      )}
      <Modal show={isDeleteTransactionsModalOpen} centered onHide={() => setIsDeleteTransactionsModalOpen(false)}>
        <div className="d-flex flex-column text-center p-5">
          <H.xs className="mb-3">{t("deleteTransactionsModal.title", { year })}</H.xs>
          <P.m className="mb-5">{t("deleteTransactionsModal.paragraph", { year })}</P.m>
          <div>
            <Button className="me-2" onClick={handleSubmit} isLoading={isSubmitting}>
              {t("deleteTransactionsModal.submit")}
            </Button>
            <Button
              variant="secondary"
              onClick={() => setIsDeleteTransactionsModalOpen(false)}
              isDisabled={isSubmitting}
            >
              {t("deleteTransactionsModal.cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RadiobuttonsSection;
