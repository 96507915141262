import { FC, useContext } from "react";
import { AccordionContext } from "react-bootstrap";
import cn from "classnames";

import { AroSteps, AroStepsIds } from "../../../types";
import CompanyInformationBody from "../../company-information/company-information-body";
import CreateLinkBody from "../../create-link/create-link-body";
import DividendsBody from "../../dividends/dividends-body";
import ShareholdersAtYearEndBody from "../../shareholders/shareholders-at-year-end/shareholders-at-year-end-body";
import ShareholdersAtYearStartBody from "../../shareholders/shareholders-at-year-start/shareholders-at-year-start-body";
import TransactionsBody from "../../transactions/transactions-body";
import classes from "../styles.module.scss";

type PT = {
  stepId: AroStepsIds;
};

const BodyContainer: FC<PT> = ({ stepId }) => {
  const { activeEventKey } = useContext(AccordionContext);

  if (stepId === activeEventKey) {
    return (
      <div className={cn(classes.whiteBackground, "px-3 pb-2")}>
        {stepId === AroSteps.CompanyInformation && <CompanyInformationBody />}
        {stepId === AroSteps.CreateLink && <CreateLinkBody />}
        {stepId === AroSteps.ShareholdersAtYearStart && <ShareholdersAtYearStartBody />}
        {stepId === AroSteps.Transactions && <TransactionsBody />}
        {stepId === AroSteps.ShareholdersAtYearEnd && <ShareholdersAtYearEndBody />}
        {stepId === AroSteps.Dividends && <DividendsBody />}
      </div>
    );
  }
};

export default BodyContainer;
