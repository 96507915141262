import { FC, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { isNil } from "ramda";

import { P } from "../../Typography";
import { useHelperAnswer } from "../helperHooks";
import classes from "./Answer.module.scss";

type HelperBannerAnswerProps = {
  answerId: string;
  children?: ReactNode;
  className?: string;
  initialShow?: boolean;
  withRightMargin?: boolean;
  text?: ReactNode;
  link?: string;
  linkText?: string;
  linkHidden?: boolean;
  UI?: ReactNode;
};
const Answer: FC<HelperBannerAnswerProps> = ({
  answerId,
  children,
  className,
  withRightMargin,
  text,
  link,
  linkText,
  linkHidden = true,
  UI,
}) => {
  const { active, Answer } = useHelperAnswer(answerId);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (active) {
      timeout = setTimeout(() => {
        setIsMounted(true);
      }, 10);
    } else {
      timeout = setTimeout(() => {
        setIsMounted(false);
      }, 500);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [active]);

  if (!active && !isMounted) {
    return null;
  }

  const data = children ? (
    children
  ) : (
    <>
      <P.s>{text}</P.s>

      {!linkHidden && linkText && link && (
        <Link className={classNames(classes.link, "mt-2")} to={link}>
          {linkText}
        </Link>
      )}
    </>
  );

  return (
    <div
      className={classNames(classes.wrap, {
        [classes.fadeIn]: isMounted,
        [classes.fadeOut]: !active,
        [classes.marginRight]: withRightMargin,
      })}
      data-answer={answerId}
    >
      {!isNil(UI) ? UI : <div className={classNames(className, classes.wrapInner)}>{Answer ? <Answer /> : data}</div>}
    </div>
  );
};

export default Answer;
