import { useCallback, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Formik } from "formik";

import { Button } from "common/components/atoms";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import BodySection from "../step-container/body-container/body-section";
import ContactPerson from "./sections/contact-person";
import GeneralInformation from "./sections/general-information";
import { CompanyInformationFormValues } from "./types";
import useCompanyInformationForm from "./use-company-information-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.companyInformation.body");

const CompanyInformationBody = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const companyInformation = useStoreState((store) => store.aroModel.companyInformation);
  const getAroDetails = useStoreActions((store) => store.aroModel.getAroDetailsThunk);
  const getCompanyInformation = useStoreActions((store) => store.aroModel.getCompanyInformationThunk);
  const setCompanyInformation = useStoreActions((store) => store.aroModel.setCompanyInformation);
  const postCompanyInformation = useStoreActions((store) => store.aroModel.postCompanyInformationThunk);

  useEffect(() => {
    companyId && getCompanyInformation(companyId);
  }, [companyId, getCompanyInformation]);

  const { initialValues, validationSchema } = useCompanyInformationForm(companyInformation);

  const submit = useCallback(
    async (values: CompanyInformationFormValues) => {
      if (companyId) {
        const result = await postCompanyInformation({ companyId, data: values });

        if (result) {
          notify(t("successToast"), true, "success");
          getAroDetails(companyId);
          setCompanyInformation(result);
        }
      }
    },
    [companyId, getAroDetails, postCompanyInformation, setCompanyInformation]
  );

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {({ submitForm, isSubmitting }) => (
        <>
          <Accordion flush alwaysOpen defaultActiveKey={["companyInfo", "companyContact"]}>
            <BodySection
              eventKey="companyInfo"
              title={t("generalInformation.title")}
              status={companyInformation?.companyDetailsStatus}
              className="mb-1_half"
            >
              <GeneralInformation />
            </BodySection>
            <BodySection
              eventKey="companyContact"
              title={t("contactPerson.title")}
              status={companyInformation?.contactDetailsStatus}
            >
              <ContactPerson />
            </BodySection>
          </Accordion>
          <Button type="submit" onClick={submitForm} isLoading={isSubmitting} className="mt-3">
            {t("submit")}
          </Button>
          <ToastFormikValidator />
        </>
      )}
    </Formik>
  );
};

export default CompanyInformationBody;
