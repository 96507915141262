import { CSSProperties, FC, forwardRef, ReactNode, useCallback, useMemo } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import cn from "classnames";

import { createTranslation, TranslationNS } from "translation";

import { OptionsType, SearchType } from "./Dropdown";
import classes from "./Dropdown.module.scss";

const t = createTranslation(TranslationNS.common, "components.dropdown");

type PropsTypes = {
  handleChange: (selectedValue: OptionsType) => void;
  show: boolean;
  options: OptionsType[];
  selected?: string | number;
  searchValue?: string;
  style?: CSSProperties;
  className?: string;
  close?: () => void;
  filterOff?: boolean;
  filterType: SearchType;
  MenuHeadSection?: ReactNode | null;
};

const filterByIncludes = (options: OptionsType[], searchValue: string) => {
  return options.filter((option) => option.name.toLowerCase().includes(searchValue.toLowerCase()));
};

const filterByStartsWith = (options: OptionsType[], searchValue: string) => {
  return options.filter((option) => option.name.toLowerCase().startsWith(searchValue.toLowerCase()));
};

const DropdownMenu = forwardRef<HTMLDivElement, PropsTypes>(
  (
    {
      style,
      className,
      show,
      searchValue,
      options = [],
      selected,
      handleChange,
      close,
      filterOff,
      filterType,
      MenuHeadSection = null,
      ...props
    },
    ref
  ) => {
    const filteredOptions = useMemo<PropsTypes["options"]>(() => {
      if (searchValue && options.length && !filterOff) {
        const filteredOp =
          filterType === "includes"
            ? filterByIncludes(options, searchValue)
            : filterType === "startsWith"
            ? filterByStartsWith(options, searchValue)
            : [];

        return filteredOp.length ? filteredOp : [{ id: t("noResults"), name: t("noResults") }];
      }

      if (options.length) {
        return options;
      }

      return searchValue ? [{ id: t("noResults"), name: t("noResults") }] : [];
    }, [filterOff, filterType, options, searchValue]);

    if (!filteredOptions.length) return null;

    const isDisabledOpt = filteredOptions[0].id === t("noResults");

    return (
      <div {...props} style={style} ref={ref} className={className} data-testid="dropdown-menu-container">
        {MenuHeadSection}

        <div className={cn("p-half overflow-auto", classes.menuScroll)}>
          {filteredOptions.map((option, i: number) => {
            return (
              <DropdownMenuItem
                option={option}
                eventKey={i}
                key={i}
                handleChange={handleChange}
                selected={selected}
                isDisabled={option?.isDisabled || isDisabledOpt}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

type DropdownMenuItemProps = Pick<PropsTypes, "handleChange" | "selected"> & {
  eventKey: number;
  option: OptionsType;
  isDisabled?: boolean;
};
const DropdownMenuItem: FC<DropdownMenuItemProps> = ({ handleChange, selected, option, eventKey, isDisabled }) => {
  const handleSelect = useCallback(() => {
    handleChange(option);
  }, [handleChange, option]);

  const isActive = selected === option.id;

  return (
    <BDropdown.Item
      key={`Dropdown key for single item is: ${option}-${eventKey}`}
      onClick={handleSelect}
      data-testid={`dropdown-menu-item-${eventKey}-test-id`}
      eventKey={eventKey}
      active={isActive}
      disabled={isDisabled}
      tabIndex={-1}
    >
      {option.element || option.name}
    </BDropdown.Item>
  );
};

DropdownMenu.displayName = "DropDownMenu";

export default DropdownMenu;
