import { useAccordionButton } from "react-bootstrap";

import { P, Tag, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import ProgrammaticAccordionToggle from "pages/equity-management/ownership/transactions/components/transactions-list/programmatic-accordion-toggle/programmatic-accordion-toggle";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { checkAroEnableStep } from "../../../aro-toast-warning/aro-toast-warning";
import { StatusTagVariantMapper } from "../../../main";
import { AroSteps } from "../../../types";
import classes from "../../styles.module.scss";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearStart.header");

const ShareholdersAtYearStartHeader = () => {
  const decoratedOnClick = useAccordionButton(AroSteps.ShareholdersAtYearStart);

  const aroDetails = useStoreState((store) => store.aroModel.aroDetails);

  const previousShareholdersStatus = useStoreState(
    (store) => store.aroModel.aroDetails?.previousShareholdersStatus || 0
  );
  const year = useStoreState((store) => store.aroModel.aroDetails?.year);
  const count = useStoreState((store) => store.aroModel.aroDetails?.previousShareholdersCount);
  const isAroDetailsLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);

  const isStepActive =
    aroDetails?.altinnConnectionStatus === AroSectionStatuses.Utfylt ||
    aroDetails?.altinnConnectionStatus === AroSectionStatuses.Forhåndsutfylt;

  return (
    <div
      onClick={checkAroEnableStep(isStepActive, decoratedOnClick)}
      className="d-flex justify-content-between py-2 px-3"
    >
      <div>
        <div className="d-flex align-items-center">
          <Ui.xl bold className="me-1">
            {t.el("title", {
              components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
              values: { date: `1. jan ${year || 2000}` },
            })}
          </Ui.xl>
          <Ui.xs className={classes.circle}>{count}</Ui.xs>
        </div>
        <P.s color="foregroundMedium">
          {t.el("subtitle", {
            components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
            values: { date: `01.01.${year || 2000}` },
          })}
        </P.s>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Tag variant={StatusTagVariantMapper[previousShareholdersStatus]}>
          <Skeleton isLoading={isAroDetailsLoading}>{AroSectionStatuses[previousShareholdersStatus]}</Skeleton>
        </Tag>
        <ProgrammaticAccordionToggle eventKey={AroSteps.ShareholdersAtYearStart} itemIdToOpen={null} />
      </div>
    </div>
  );
};

export default ShareholdersAtYearStartHeader;
