import { FC, useCallback } from "react";

import { Button, H } from "common/components/atoms";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import WizardCloseConfirm from "common/layout/WizardLayout/WizardCloseConfirm/WizardCloseConfirm";
import { createTranslation, TranslationNS } from "translation";

type PT = {
  handleClose(): void;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.common.closeConfirm");

const CloseConfirm: FC<PT> = ({ handleClose }) => {
  const setCloseConfirmOpenAction = WizardLayoutStore.useStoreActions((actions) => actions.setCloseConfirmOpen);

  const handleStayClick = useCallback(() => {
    setCloseConfirmOpenAction(false);
  }, [setCloseConfirmOpenAction]);

  return (
    <WizardCloseConfirm onExit={handleClose}>
      <H.xxs className="mb-5 text-center">{t("paragraph")}</H.xxs>

      <div className="d-flex justify-content-center">
        <Button variant="danger" className="w-max-content me-2" onClick={handleClose}>
          {t("leave")}
        </Button>

        <Button variant="secondary" className="w-max-content" onClick={handleStayClick}>
          {t("stay")}
        </Button>
      </div>
    </WizardCloseConfirm>
  );
};

export default CloseConfirm;
