import { memo, useMemo } from "react";
import { endOfYear, set, startOfYear } from "date-fns";
import { useFormikContext } from "formik";

import { DatePicker, NewAvatar, TextField, Ui } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";

import { StakeholderDividend } from "../../types";

const CalculatedTableBody = memo(() => {
  const year = useStoreState((state) => state.aroModel.aroDetails?.year || 2000);
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext<StakeholderDividend[]>();

  const dateLimits = useMemo(() => {
    const today = new Date(year, 0, 1);

    const start = set(startOfYear(today), { hours: 0, minutes: 0, seconds: 0 });
    const end = set(endOfYear(today), { hours: 23, minutes: 59, seconds: 59 });

    return { start, end };
  }, [year]);

  return (
    <tbody>
      {values.map((stakeholder, index) => (
        <tr key={stakeholder.stakeholderId}>
          <td>
            <div className="d-flex align-items-center">
              <NewAvatar
                size="m"
                imageUrl={stakeholder.avatarFilePath}
                company={stakeholder.isCompanyOwned}
                initials={stakeholder.initials}
              />
              <div className="ms-2">
                <Ui.s bold className="me-1">
                  {stakeholder.shareholderName || "-"}
                </Ui.s>

                {stakeholder.isCompanyOwned ? (
                  <Ui.s style={{ color: scssVariables.foregroundLow }}>{stakeholder.representedBy}</Ui.s>
                ) : null}
              </div>
            </div>
          </td>
          <td>
            <DatePicker
              name={`[${index}].dividendDate`}
              date={stakeholder.dividendDate}
              onChange={(date) => setFieldValue(`[${index}].dividendDate`, date)}
              minDate={dateLimits.start}
              maxDate={dateLimits.end}
              isTouched={touched[index]?.dividendDate}
              error={errors[index]?.dividendDate}
            />
          </td>
          <td>
            <TextField
              type="number"
              name={`[${index}].dividendAmount`}
              value={stakeholder.dividendAmount}
              onChange={handleChange}
              isTouched={touched[index]?.dividendAmount}
              error={errors[index]?.dividendAmount}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
});
export default CalculatedTableBody;
