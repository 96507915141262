import * as Yup from "yup";

import { createTranslation, TranslationNS } from "translation";

import { CreateLinkFormValues } from "../company-information/types";

const t = createTranslation(TranslationNS.validation);

const validationSchema = () =>
  Yup.object().shape({
    loginPassword: Yup.string().required(t("required")),
    birthNumber: Yup.number().typeError(t("integer")).required(t("required")),
    altinnId: Yup.number().typeError(t("integer")).required(t("required")),
    isVerificationSuccess: Yup.boolean(),
    dataSystemPassword: Yup.string().required(t("required")),
    verificationCode: Yup.string().when("isVerificationSuccess", {
      is: true,
      then: Yup.string().required(t("required")),
    }),
  });

const useCreateLinkForm = (initialData?: CreateLinkFormValues) => {
  const initialValues: CreateLinkFormValues = {
    loginPassword: initialData?.loginPassword || "",
    birthNumber: initialData?.birthNumber || "",
    altinnId: initialData?.altinnId || "",
    dataSystemPassword: initialData?.dataSystemPassword || "",
    verificationCode: initialData?.verificationCode || "",

    // internal value for form to determine current step - show sms field or send verification code field
    // also switching API calls for submit callback
    isVerificationSuccess: false,
  };

  return {
    validationSchema,
    initialValues,
  };
};

export default useCreateLinkForm;
