import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useStoreState } from "../../store/store";
import { StripeSubscriptionTypes } from "../enums/enum";

const useSubscriptionType = () => {
  const { companyId = "0" } = useParams();
  const account = useStoreState((state) => state.account);

  const subscriptionType = useMemo(() => {
    return account?.user?.companies?.find((el) => el.id === Number(companyId))?.subscriptionType;
  }, [account?.user?.companies, companyId]);

  return {
    hasSubscriptionType: !!subscriptionType,
    subscriptionType: subscriptionType || StripeSubscriptionTypes.NoSubscription,
  };
};

export default useSubscriptionType;
