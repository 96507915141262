import { FC, useContext, useMemo } from "react";
import { AccordionContext } from "react-bootstrap";
import cn from "classnames";

import { AroSteps, AroStepsIds } from "../../types";
import CompanyInformationHeader from "../company-information/company-information-header";
import CreateLinkHeader from "../create-link/create-link-header";
import DividendsHeader from "../dividends/dividends-header";
import ShareholdersAtYearEndHeader from "../shareholders/shareholders-at-year-end/shareholders-at-year-end-header";
import ShareholdersAtYearStartHeader from "../shareholders/shareholders-at-year-start/shareholders-at-year-start-header";
import TransactionsHeader from "../transactions/transactions-header";
import classes from "./styles.module.scss";

type PT = {
  stepId: AroStepsIds;
};

const HeaderContainer: FC<PT> = ({ stepId }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const isStepExpanded = useMemo(() => {
    if (!activeEventKey) {
      return false;
    }
    if (typeof activeEventKey === "string") {
      return activeEventKey === stepId;
    }

    if (typeof activeEventKey === "object") {
      return activeEventKey.find((key) => key === stepId);
    }
  }, [activeEventKey, stepId]);

  return (
    <div
      className={cn(
        classes.whiteBackground,
        { [classes.hoverBorder]: !isStepExpanded, [classes.activeBorder]: isStepExpanded },
        "w-100 cursor-pointer"
      )}
    >
      {stepId === AroSteps.CompanyInformation && <CompanyInformationHeader />}
      {stepId === AroSteps.CreateLink && <CreateLinkHeader />}
      {stepId === AroSteps.ShareholdersAtYearStart && <ShareholdersAtYearStartHeader />}
      {stepId === AroSteps.Transactions && <TransactionsHeader />}
      {stepId === AroSteps.ShareholdersAtYearEnd && <ShareholdersAtYearEndHeader />}
      {stepId === AroSteps.Dividends && <DividendsHeader />}
    </div>
  );
};

export default HeaderContainer;
