export type StakeholderDividend = {
  dividendDate: string;
  dividendAmount: number;
  stakeholderId: number;
  shareholderName: string;
  shareClassId: number;
  shareClassName: string;
  initials: string;
  representedBy: string;
  isCompanyOwned: boolean;
  avatarFilePath: string;
};

export type Dividends = {
  dividendMode?: DividendsModes;
  stakeholderDividends: StakeholderDividend[];
};

export enum DividendsModes {
  NoDividend,
  AutomaticDividend,
  ManualDividend,
}

export type CalculateForm = {
  totalDividend?: number;
  dividendDate?: string;
};

export type StakeholderDividendForm = {
  key: number;
  stakeholderId?: number;
  dividendDate: string;
  dividendAmount: number;
  shareClassId?: number; // required for manual table
};

export type DividendsForm = {
  stakeholderDividends: Omit<StakeholderDividendForm, "key">[];
};
