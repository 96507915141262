import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { Ui } from "common/components/atoms";
import { SelectInput, TextInput } from "common/components/atoms/ImportTable";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { ShareholderEditFormValues } from "../../types";
import { fields } from "./use-edit-shareholders-form";

const t = createTranslation(
  TranslationNS.pages,
  "aro.main.steps.shareholdersAtYearEnd.body.shareholdersEditForm.tableColumns"
);

const useEditTransactionsColumns = () => {
  const fNumber = useFormatNumbers();

  const countries = useStoreState((state) => state.common.dropdowns)?.countries;

  const countriesWithEmpty = useMemo(() => {
    const notSelected = {
      id: 0,
      name: "Ikke valgt",
    };

    return countries ? countries : [notSelected];
  }, [countries]);

  const columns = useMemo<ColumnDef<ShareholderEditFormValues>[]>(() => {
    const cells: ColumnDef<ShareholderEditFormValues>[] = [
      {
        header: t("shareholder"),
        accessorKey: fields.shareholderName,
        cell: (props) => {
          const name = props.row.getValue(fields.shareholderName) as string;

          return <Ui.s>{name}</Ui.s>;
        },
        minSize: 200,
        footer: "",
      },
      {
        header: t("isin"),
        accessorKey: fields.isin,
        cell: (props) => {
          return <TextInput rowIndex={props.row.index} columnId={props.column.id} />;
        },
        minSize: 200,
      },

      {
        header: "Adresse",
        accessorKey: fields.adresse,
        cell: (props) => {
          return <TextInput rowIndex={props.row.index} columnId={props.column.id} />;
        },
        minSize: 200,
      },
      {
        header: "Postnummer",
        accessorKey: fields.postnummer,
        cell: (props) => {
          return <TextInput rowIndex={props.row.index} columnId={props.column.id} />;
        },
        minSize: 200,
      },
      {
        header: "Poststed",
        accessorKey: fields.poststed,
        cell: (props) => {
          return <TextInput rowIndex={props.row.index} columnId={props.column.id} />;
        },
        minSize: 200,
      },
      {
        header: "Land",
        accessorKey: fields.land,
        cell: (props) => {
          return (
            <SelectInput
              isSearchable
              rowIndex={props.row.index}
              columnId={props.column.id}
              options={countriesWithEmpty}
              placeholder="Ikke valgt"
              isClearable
              searchType="startsWith"
            />
          );
        },
        minSize: 200,
      },
      {
        header: t("numberOfShares"),
        accessorKey: fields.numberOfShares,
        cell: (props) => {
          const numberOfShares = props.row.getValue(fields.numberOfShares) as number;

          return <Ui.s>{fNumber(numberOfShares, "amount")}</Ui.s>;
        },
        minSize: 200,
      },
    ];

    return cells;
  }, [countriesWithEmpty, fNumber]);

  return { columns };
};

export default useEditTransactionsColumns;
