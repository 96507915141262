import React, { FC } from "react";
import { Form, useFormikContext } from "formik";

import { Button, P, SlidePanel } from "common/components/atoms";
import DocumentUploadWithStatuses from "common/components/molecules/DocumentUploadWithStatuses/DocumentUploadWithStatuses";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.transactions.manageDocuments");

type Props = {
  onClose: () => void;
};

const ManageDocumentsForm: FC<Props> = ({ onClose }) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Form>
      <ToastFormikValidator />

      <SlidePanel.Section title={t("documentation")} isSingle>
        <P.m className="mb-5">{t("documentationDescription")}</P.m>

        <DocumentUploadWithStatuses documentLoaderSize="large" />
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button isLoading={isSubmitting} isDisabled={isSubmitting} className="me-2" type={"submit"}>
          {t("updateDocuments")}
        </Button>
        <Button isDisabled={isSubmitting} variant="secondary" onClick={onClose}>
          {t("cancel")}
        </Button>
      </SlidePanel.Actions>
    </Form>
  );
};

export default ManageDocumentsForm;
