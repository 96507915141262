import { FC, useCallback, useContext, useEffect } from "react";
import axios from "axios";
import { useFormikContext } from "formik";
import { defaultTo } from "ramda";

import StakeholdersLimitBanner from "common/components/atoms/StakeholdersLimitBanner/StakeholdersLimitBanner";
import { useStoreState } from "store/store";

import useCompanyRemainingStakeholders from "../../../../hooks/useCompanyRemainingStakeholders";
import Company from "../Company/Company";
import StakeholderCompanyField from "../fields/StakeholderIsCompanyField";
import Personal from "../Personal/Personal";
import { StakeholderFormValues } from "../Stakeholder.types";
import { StakeholderSectionContext } from "../StakeholderSection";

type PT = {
  checkSeatsRequest?: boolean;
  isAroFlow?: boolean;
};

const NewStakeholder: FC<PT> = ({ checkSeatsRequest, isAroFlow }) => {
  const { values, setValues } = useFormikContext<StakeholderFormValues & { subscriptionAvailable?: any }>();

  const updatedFields = useContext(StakeholderSectionContext).updatedFields;

  const { companyId } = useStoreState((state) => state.activeCompanyModel);

  const { remainingStakeholders } = useCompanyRemainingStakeholders(companyId || 0);

  const isFormDisabled = checkSeatsRequest && defaultTo(0, values?.subscriptionAvailable?.remainingAgreements) <= 0;

  const checkAvailableSeats = useCallback(async () => {
    try {
      const request = await axios.post("/api/ownership/plan/check-stakeholder", {
        companyId,
      });

      if (request.status === 200) {
        setValues({ ...values, subscriptionAvailable: request.data });
      }
    } catch (e) {
      console.error({ e });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (checkSeatsRequest) {
      checkAvailableSeats();
    }
  }, [checkAvailableSeats, checkSeatsRequest]);

  if (remainingStakeholders !== null && remainingStakeholders <= 0) {
    return <StakeholdersLimitBanner />;
  }

  return (
    <div>
      <StakeholderCompanyField className="mb-4" disabled={isFormDisabled} />
      {values.isCompanyOwned ? (
        <Company updatedFields={updatedFields} isDisabled={isFormDisabled} isAroFlow={isAroFlow} />
      ) : (
        <Personal updatedFields={updatedFields} isDisabled={isFormDisabled} isAroFlow={isAroFlow} />
      )}
    </div>
  );
};

export default NewStakeholder;
