import { FC, useMemo, useState } from "react";

import CTable from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import { Shareholder } from "../../types";
import ShareholdersTableBody from "./shareholders-table-body";
import ShareholdersTableHeader from "./shareholders-table-header";
import classes from "./styles.module.scss";
import { HeaderRow, ShareholdersTableKeys, ShareholdersTableSorting } from "./types";

type PT = {
  isLoading: boolean;
  shareholders: Shareholder[];
};

const t = createTranslation(
  TranslationNS.pages,
  "aro.main.steps.shareholdersAtYearStart.body.listOfShareholders.tableColumns"
);

const ShareholdersTable: FC<PT> = ({ isLoading, shareholders }) => {
  const [activeSortedValue, setActiveSortedValue] = useState<ShareholdersTableSorting>({
    field: ShareholdersTableKeys.missingInfo,
    sortDirection: sortingParams.inc,
  });

  const rows: HeaderRow[] = useMemo(
    () => [
      {
        key: ShareholdersTableKeys.shareholderName,
        value: t("shareholder"),
        sortDisabled: true,
      },

      {
        key: ShareholdersTableKeys.isin,
        value: t("isin"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.address,
        value: t("address"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.postCode,
        value: t("postCode"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.city,
        value: t("city"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.country,
        value: t("country"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.shareClass,
        value: t("shareClass"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.numberOfShares,
        value: t("numberOfShares"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.missingInfo,
        value: t("missingInfo"),
      },
    ],
    []
  );

  return (
    <div className={classes.tableWrapper}>
      <CTable
        className={classes.table}
        headComponent={<ShareholdersTableHeader rows={rows} sort={activeSortedValue} setSort={setActiveSortedValue} />}
        bodyComponent={<ShareholdersTableBody shareholders={shareholders} sort={activeSortedValue} />}
      />
    </div>
  );
};

export default ShareholdersTable;
