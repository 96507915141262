import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { Button, P, ToggleSwitch, Ui } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../service/aro-service";
import EditTransactionsTable from "./edit-transactions-table/edit-transactions-table";
import RadiobuttonsSection from "./radiobuttons-section/radiobuttons-section";
import classes from "./styles.module.scss";
import TransactionsTable from "./transactions-table/transactions-table";
import { TransactionsMode } from "./types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body");

const TransactionsBody = memo(() => {
  const { companyId } = useParams<{ companyId: string }>();
  const [isEditTableOpen, setIsEditTableOpen] = useState(false);

  const transactions = useStoreState((state) => state.aroModel.transactions);
  const isLoading = useStoreState((state) => state.aroModel.isTransactionsLoading);
  const getTransactions = useStoreActions((actions) => actions.aroModel.getTransactionsThunk);
  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);
  const getShareholdersAtYearEnd = useStoreActions((actions) => actions.aroModel.getShareholdersAtYearEndThunk);

  useEffect(() => {
    if (companyId) {
      getTransactions(companyId);
    }
  }, [companyId, getTransactions]);

  const handleChangeTransactionsDone = useCallback(async () => {
    if (companyId) {
      const success = await aroService.postTransactionsDone({
        companyId,
        transactionsDone: !transactions?.allTransactionsDone,
      });

      if (success) {
        notify(t("successToast"), true, "success");
        getAroDetails(companyId);
        getTransactions(companyId);
        getShareholdersAtYearEnd(companyId);
      }
    }
  }, [companyId, getAroDetails, getShareholdersAtYearEnd, getTransactions, transactions?.allTransactionsDone]);

  const handleOpenEditTableClick = useCallback(() => {
    setIsEditTableOpen(true);
  }, []);

  const handleCloseEditTableClick = useCallback(() => {
    setIsEditTableOpen(false);
  }, []);

  return (
    <div>
      <RadiobuttonsSection className="mb-2" handleDeleteTransactions={handleOpenEditTableClick} />
      {transactions?.transactionsMode === TransactionsMode.ImportTransactions && (
        <>
          <div className={cn(classes.backgroundElement, "rounded-3 mb-2")}>
            <div className={cn("d-flex justify-content-between py-2 px-3", classes.borderBottom)}>
              <div>
                <Ui.m bold>{t("transactionsTable.title")}</Ui.m>
                <P.s>{t("transactionsTable.paragraph")}</P.s>
              </div>
              <Button onClick={handleOpenEditTableClick}>{t("transactionsTable.editBtn")}</Button>
            </div>
            <div className={cn(classes.tableWrapper, "p-3")}>
              <TransactionsTable isLoading={isLoading} transactions={transactions?.transactions} />
            </div>
          </div>

          {transactions?.transactions?.length !== 0 && (
            <div
              className={cn(
                {
                  [classes.backgroundInfo]: !transactions.allTransactionsDone,
                  [classes.backgroundPositive]: transactions.allTransactionsDone,
                },
                "d-flex justify-content-between rounded-3 py-2 px-3"
              )}
            >
              <div>
                <Ui.m bold>{t("checkTransactionsDone.title")}</Ui.m>
                <P.s>{t("checkTransactionsDone.paragraph")}</P.s>
              </div>
              <ToggleSwitch checked={transactions.allTransactionsDone} onChange={handleChangeTransactionsDone} />
            </div>
          )}
        </>
      )}
      {isEditTableOpen && <EditTransactionsTable onClose={handleCloseEditTableClick} />}
    </div>
  );
});

export default TransactionsBody;
