import { H, P } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import useSubscriptionType from "../../../hooks/useSubscriptionType";
import BottomBanner from "./components/BottomBanner";
import classes from "./templates.module.scss";

const t = createTranslation(TranslationNS.common, "noSubscription.documentsTemplate");

const DocumentsTemplate = () => {
  const { hasSubscriptionType } = useSubscriptionType();
  return (
    <div className={classes.wrap}>
      <div className={classes.topBanner}>
        <div className="w-50">
          <H.xs color="foregroundHigh">{hasSubscriptionType ? t("title_u") : t("title_s")}</H.xs>
          <P.m color="foregroundHigh" className="mt-3">
            {t("description_1")}
          </P.m>
          <ul>
            <li>
              <P.m color="foregroundHigh" className="mt-3">
                {t("description_2")}
              </P.m>
            </li>
            <li>
              <P.m color="foregroundHigh" className="mt-3">
                {t("description_3")}
              </P.m>
            </li>
          </ul>
        </div>
        <div className="w-50">
          <img src={"/banners/documentsTemplateTopBanner.png"} alt="Pools and Programs" />
        </div>
      </div>
      <BottomBanner
        bannerImage="/banners/documentsTemplateBottomBanner.png"
        title={t(`bottomBannerTitle_${hasSubscriptionType ? "u" : "s"}`)}
      />
    </div>
  );
};

export default DocumentsTemplate;
