import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";
import { clone, isNil, omit } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { OnboardingMethodsEnum } from "common/enums/enum";
import WithRouteAnimation from "common/HOC/WithRouteAnimation";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { useStoreActions, useStoreState } from "store/store";

import useOnboardCompanyIntegration from "../useOnboardCompanyIntegration";
import CompanyInformationForm from "./CompanyInformationForm";
import IncludeTransactionModal from "./IncludeTransactionModal/IncludeTransactionModal";
import { fields, OnboardingCompanyBasicFormValues, useCompanyInformationForm } from "./useCompanyInformationForm";

const CompanyInformation: FC = () => {
  useOnboardCompanyIntegration(false);

  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId?: string }>();

  const [showModal, setShowModal] = useState(false);
  const [newCompanyActivated, setNewCompanyActivated] = useState(false);

  const { subscription, connectCompanySubscription } = useStripeSubscription(Number(companyId));

  const invitedUser = useStoreState((state) => state.invitedUser.invitedUser);
  const integrations = useStoreState((state) => state.companyOnboardingModel.integrations);
  const companyType = useStoreState((state) => state.companyOnboardingModel.companyType);
  const setInvitedUser = useStoreActions((actions) => actions.invitedUser.setInvitedUser);
  const setCompanyId = useStoreActions((state) => state.activeCompanyModel.setCompanyId);
  const { setCompanyThunk, setIntegration } = useStoreActions((actions) => actions.companyOnboardingModel);

  const isSimplifyFlow = companyType === "few-owners";

  const { initialValues, validationSchema } = useCompanyInformationForm();

  const handleManageModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const submitHandler = useCallback<FormikConfig<OnboardingCompanyBasicFormValues>["onSubmit"]>(
    async (values, { resetForm }) => {
      try {
        let onboardingMethod = OnboardingMethodsEnum.NO_INTEGRATION;

        if (companyType === "few-owners" && integrations?.bregg) {
          onboardingMethod = OnboardingMethodsEnum.FEW_OWNERS_COMPANY;
        } else if (companyType !== "few-owners" && integrations?.bregg) {
          onboardingMethod = OnboardingMethodsEnum.BR_REG;
        } else if (integrations?.uniMicro && companyType !== "few-owners") {
          onboardingMethod = integrations.uniMicro?.onboardingMethod || OnboardingMethodsEnum.UNI_MICRO;
        } else if (integrations?.uniMicro && companyType === "few-owners") {
          onboardingMethod = OnboardingMethodsEnum.FEW_OWNERS_COMPANY;
        } else if (companyType === "few-owners") {
          onboardingMethod = OnboardingMethodsEnum.FEW_OWNERS_COMPANY;
        }

        const copiedValues = isSimplifyFlow
          ? omit([fields.nominalShareValue], clone(values))
          : omit([fields.numberOfShares], clone(values));

        const company = await setCompanyThunk({
          ...copiedValues,
          companyId: companyId ? Number(companyId) : undefined,
          shareClasses: integrations?.bregg?.shareClasses || integrations?.uniMicro?.shareClasses || [],
          onboardingMethod,
          companyInvitationId: invitedUser?.entityId ? Number(invitedUser?.entityId) : undefined,
        });

        if (company) {
          resetForm();
          setCompanyId(company?.data?.companyId || 0);

          setIntegration({
            type: "regular",
            data: { companyId: company?.data?.companyId || 0, withTransaction: false },
          });

          navigate(getPath(["onboard", "company", "companyInformation"], { companyId: company?.data?.companyId }), {
            replace: true,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [
      companyType,
      integrations?.bregg,
      integrations.uniMicro,
      isSimplifyFlow,
      setCompanyThunk,
      companyId,
      invitedUser?.entityId,
      setCompanyId,
      setIntegration,
      navigate,
    ]
  );

  useEffect(() => {
    if (!isNil(subscription) && !subscription?.hasSubscription && invitedUser?.entityId && companyId) {
      connectCompanySubscription().then((res: any) => {
        if (res.subscriptionFound) {
          if (companyType === "few-owners") {
            navigate(getPath(["onboard", "company", "importFounders"], { companyId }));
          } else if (companyType === "many-owners" && (integrations?.bregg || integrations?.uniMicro)) {
            navigate(getPath(["onboard", "company", "importStakeholders"], { companyId }));
          } else {
            navigate(getPath(["onboard", "company", "shareClasses"], { companyId }), {
              replace: true,
            });
          }
        }
        if (res) {
          navigate(getPath(["onboard", "company", "pricing"], { companyId }));
          setInvitedUser(null);
        }
        console.log({ res });
      });
    }
  }, [
    companyId,
    companyType,
    connectCompanySubscription,
    integrations?.bregg,
    integrations?.uniMicro,
    invitedUser?.entityId,
    navigate,
    setInvitedUser,
    subscription,
    subscription?.hasSubscription,
  ]);

  useEffect(() => {
    if (!newCompanyActivated) {
      if (companyId && !isNil(subscription) && subscription?.hasSubscription) {
        if (companyType === "few-owners") {
          navigate(getPath(["onboard", "company", "importFounders"], { companyId }));
        } else if (companyType === "many-owners" && (integrations?.bregg || integrations?.uniMicro)) {
          navigate(getPath(["onboard", "company", "importStakeholders"], { companyId }));
        } else {
          navigate(getPath(["onboard", "company", "shareClasses"], { companyId }), {
            replace: true,
          });
        }

        setInvitedUser(null);
      }

      if (companyId && !isNil(subscription) && !subscription?.hasSubscription && !invitedUser?.entityId) {
        navigate(getPath(["onboard", "company", "pricing"], { companyId }));

        setInvitedUser(null);
      }
    }
  }, [
    companyId,
    companyType,
    integrations?.bregg,
    integrations?.uniMicro,
    invitedUser?.entityId,
    navigate,
    newCompanyActivated,
    setInvitedUser,
    subscription,
    subscription?.hasSubscription,
  ]);

  useEffect(() => {
    if (!isNil(invitedUser?.entityId)) {
      setNewCompanyActivated(true);
    }
  }, [invitedUser?.entityId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      <>
        <CompanyInformationForm />
        <IncludeTransactionModal show={showModal} handleClose={handleManageModal} />
      </>
    </Formik>
  );
};

export default WithRouteAnimation(CompanyInformation);
