import { FC } from "react";

import { Ui } from "common/components/atoms";

import { HeadColumn } from "./types";

type PT = {
  columns: HeadColumn[];
};

const ShareholdersTableHeader: FC<PT> = ({ columns }) => {
  return (
    <thead>
      <tr>
        {columns.map((el) => (
          <th key={el.key}>
            {el.key === "name" ? (
              <Ui.m className="d-flex justify-content-start" bold color="foregroundMedium">
                {el.value}
              </Ui.m>
            ) : (
              <Ui.xs className="d-flex justify-content-end">{el.value}</Ui.xs>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ShareholdersTableHeader;
