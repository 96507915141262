import { FC } from "react";
import { useFormikContext } from "formik";

import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDividend } from "../types";

type PT = {
  variant?: "calculated" | "manual";
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.body.table");

const DividendsTableFooter: FC<PT> = ({ variant = "calculated" }) => {
  const fNumber = useFormatNumbers();
  const { values } = useFormikContext<StakeholderDividend[]>();

  const total = values.reduce((acc, stakeholder) => acc + Number(stakeholder.dividendAmount), 0);

  return (
    <tfoot>
      <tr>
        <th>{t("total")}</th>
        <th></th>
        <th className="text-end">{fNumber(total, "value")}</th>
        {variant === "manual" && <th></th>}
      </tr>
    </tfoot>
  );
};

export default DividendsTableFooter;
