import { FC } from "react";

import { NewAvatar, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";

const TableLoading: FC = () => (
  <tr>
    <td>
      <div className="d-flex align-items-center">
        <NewAvatar size="s" />

        <div className="ms-2">
          <div className="d-flex align-items-center">
            <Ui.s bold className="me-1">
              <Skeleton isLoading={true}>Shareholder Name</Skeleton>
            </Ui.s>
          </div>
        </div>
      </div>
    </td>
    <td>
      <Ui.s>
        <Skeleton isLoading={true}>Share class name</Skeleton>
      </Ui.s>
    </td>
    <td className="text-end">
      <Ui.s>
        <Skeleton isLoading={true}>123 456</Skeleton>
      </Ui.s>
    </td>
  </tr>
);

export default TableLoading;
