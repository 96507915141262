import { FC } from "react";
import cn from "classnames";

import { P } from "common/components/atoms";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../styles.module.scss";
import ShareclassTable from "./shareclass-table/shareclass-table";
import ShareclassTableLoading from "./shareclass-table/shareclass-table-loading";

type PT = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body");

const ShareClasses: FC<PT> = ({ className }) => {
  const year = useStoreState((state) => state.aroModel.aroDetails?.year || 2000);
  const shareClasses = useStoreState((state) => state.aroModel.shareholdersAtYearEnd?.shareClasses);
  const isLoading = useStoreState((state) => state.aroModel.isShareholdersAtYearEndLoading);

  return (
    <div className={cn(classes.backgroundElement, "rounded-3 p-3", className)}>
      <P.s color="foregroundMedium" className="mb-3">
        {t("paragraph", {
          year,
          lastYear: year - 1,
        })}
      </P.s>
      <div>
        {isLoading ? (
          <ShareclassTableLoading />
        ) : (
          shareClasses?.map((shareClass, index) => (
            <ShareclassTable shareClass={shareClass} key={shareClass.id} className={index !== 0 ? "mt-2" : undefined} />
          ))
        )}
      </div>
    </div>
  );
};

export default ShareClasses;
