import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import { assoc, isEmpty, omit } from "ramda";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import StakeholdersLimitBanner from "common/components/atoms/StakeholdersLimitBanner/StakeholdersLimitBanner";
import NewStakeholder from "common/components/molecules/StakeholderSection/components/NewStakeholder";
import useCompanyRemainingStakeholders from "common/hooks/useCompanyRemainingStakeholders";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useStakeholdersService } from "../stakeholders-management-service";
import { EditStakeholderDTO, PostStakeholderDTO } from "../types";
import StakeholderFormContacts from "./stakeholder-form-contacts";

type PropsTypes = {
  isOpen: boolean;
  onClose: () => void;
  stakeholderId?: number;
  onCreateSuccess: (createdId?: number) => Promise<void>;
  isAroFlow?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.form");

const StakeholderForm: FC<PropsTypes> = ({ onClose, stakeholderId, onCreateSuccess, isAroFlow }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const { remainingStakeholders } = useCompanyRemainingStakeholders(companyId, false);

  const [loading, setLoading] = useState(!!stakeholderId);
  const {
    selectedContactIndex,
    setSelectedContactIndex,
    getStakeholderRequest,
    validationSchema,
    formInitialValues,
    postStakeholderRequest,
    editStakeholderRequest,
  } = useStakeholdersService(companyId, isAroFlow);

  const handleSubmitForm = useCallback(
    async (
      stakeholder: (PostStakeholderDTO | EditStakeholderDTO) & Record<"internalFormSubmitted", boolean>,
      helpers: FormikHelpers<any>
    ) => {
      if (stakeholder.internalFormSubmitted) {
        setSelectedContactIndex(undefined);
        helpers.setFieldValue("editingContact", false);
        helpers.setFieldValue("addContactFormOpen", false);
        helpers.setFieldValue("internalFormSubmitted", false);
        return;
      }

      let isSuccessOrId;
      let stakeholderData = assoc(
        "contacts",
        stakeholder.contacts.map((el) => ({
          ...el,
          stakeholderContactRoleId: el.stakeholderContactRoleId ? el.stakeholderContactRoleId : null,
        })),
        stakeholder
      );

      if (isEmpty(stakeholderData.dateOfBirth)) {
        stakeholderData = omit(["dateOfBirth"], stakeholderData);
      }

      if (stakeholderId) {
        isSuccessOrId = await editStakeholderRequest(stakeholderData as unknown as EditStakeholderDTO);
      } else {
        isSuccessOrId = await postStakeholderRequest(stakeholderData as unknown as PostStakeholderDTO);
      }

      if (isSuccessOrId) {
        notify(t(stakeholderId ? "editNotificationSuccess" : "addNotificationSuccess"), true, "success", 2000);

        onClose();

        helpers.setFieldValue("addContactFormOpen", false);
        helpers.setFieldValue("editingContact", false);

        if (typeof isSuccessOrId === "number") {
          await onCreateSuccess(isSuccessOrId);
        }
        await onCreateSuccess();
      }
    },
    [stakeholderId, setSelectedContactIndex, editStakeholderRequest, postStakeholderRequest, onClose, onCreateSuccess]
  );

  useEffect(() => {
    if (stakeholderId) {
      setLoading(true);
      getStakeholderRequest(stakeholderId).then(() => {
        setLoading(false);
      });
    }
  }, [getStakeholderRequest, stakeholderId]);

  const stakeholdersLimitReached = remainingStakeholders !== null && remainingStakeholders <= 0;

  return (
    <SlidePanel.Body isLoading={loading}>
      {!stakeholderId && stakeholdersLimitReached ? (
        <StakeholdersLimitBanner />
      ) : (
        <Formik onSubmit={handleSubmitForm} initialValues={formInitialValues} validationSchema={validationSchema}>
          {({ values, isSubmitting, submitForm }) => {
            return (
              <Form>
                <ToastFormikValidator />
                <SlidePanel.Section title={t("stakeholderDetails")}>
                  <NewStakeholder isAroFlow={isAroFlow} />
                </SlidePanel.Section>

                {values.isCompanyOwned ? (
                  <SlidePanel.Section title={t("contacts.title")}>
                    <StakeholderFormContacts
                      selectedContactIndex={selectedContactIndex}
                      setSelectedContactIndex={setSelectedContactIndex}
                    />
                  </SlidePanel.Section>
                ) : null}

                <SlidePanel.Actions
                  primaryTitle={stakeholderId ? t("editBtn") : t("createBtn")}
                  secondaryTitle={t("cancelBtn")}
                  primaryAction={submitForm}
                  secondaryAction={onClose}
                  isLoading={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </SlidePanel.Body>
  );
};

const StakeholderFormSidebar: FC<PropsTypes> = ({ isOpen, onClose, stakeholderId, onCreateSuccess, isAroFlow }) => {
  return (
    <SlidePanel style={{ zIndex: 1056 }} show={isOpen} onHide={onClose}>
      <SlidePanel.Header
        isEdit={!!stakeholderId}
        onHide={onClose}
        closeBtn
        title={stakeholderId ? t("editTitle") : t("createTitle")}
      />
      <StakeholderForm
        isOpen={isOpen}
        onClose={onClose}
        stakeholderId={stakeholderId}
        onCreateSuccess={onCreateSuccess}
        isAroFlow={isAroFlow}
      />
    </SlidePanel>
  );
};

export default StakeholderFormSidebar;
