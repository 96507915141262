import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios, { AxiosRequestHeaders, isCancel } from "axios";
import * as R from "ramda";

import authProvider, { loginRequest } from "common/authentication/authProvider";
import { handleLogout } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import classes from "common/utils/notify/toasts.module.scss";
import { createTranslation } from "translation/helpers";

import store from "../../store/store";

const translation = createTranslation("common");

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(async (config) => {
  try {
    if (!R.isNil(authProvider.getActiveAccount())) {
      const account = authProvider.getActiveAccount();
      const { accessToken } = await authProvider.acquireTokenSilent({
        ...loginRequest,
        account: account || undefined,
      });

      const bearerToken = `Bearer ${accessToken}`;

      if (!config.headers) {
        config.headers = {
          Authorization: bearerToken,
        } as AxiosRequestHeaders;
      } else {
        config.headers["Authorization"] = bearerToken;
      }
    }

    return config;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      try {
        handleLogout(authProvider);
        await authProvider.acquireTokenRedirect(loginRequest);
      } catch (e: any) {
        if (e.errorMessage === "User cancelled the flow.") {
          handleLogout(authProvider);
        }
      }
    }

    return config;
  }
});

axios.interceptors.response.use(
  (res) => {
    const refreshUserApis = [
      { api: "/api/onboarding/company", method: "post" },
      { api: "/api/onboarding/company/shareholders", method: "post" },
      { api: "/api/onboarding/company/transactions", method: "post" },
      { api: "/api/equity-management/issue-equity/warrant/accept-warrant-issued", method: "post" },
      { api: "/api/attract/accept-team-invitation", method: "post" },
      { api: "/api/ownership/stakeholder/accept-invitation", method: "post" },
      { api: "/api/equity-management/issue-equity/warrant/accept-warrant-settlement", method: "post" },
      { api: "/api/ownership/ownership-plan/manager-sign-plans", method: "post" },
      { api: "/api/ownership/plan/stock-options/accept-plan-invitation", method: "post" },
      { api: "/api/ownership/plan/rsa/accept-plan-invitation", method: "post" },
      { api: "/api/ownership/transaction/shares/accept-shares-issued", method: "post" },
      { api: "/api/equity-management/issue-equity/convertible-note/accept-convertible-note-issued", method: "post" },
      { api: "/api/equity-management/issue-equity/convertible-loan/accept-convertible-loan-issued", method: "post" },
      { api: "/api/company/user-access/invitation", method: "post" },
    ];

    if (R.includes({ api: res.config?.url, method: res.config?.method }, refreshUserApis)) {
      store.getActions().account.getAccountThunk({ withAdditionalDataLoading: true });
    }

    return res;
  },
  async (err) => {
    const url = err?.config?.url || "";

    if (isCancel(err)) {
      return Promise.reject(err);
    }

    if (url === "/api/users/current" && err?.response?.status === 401) {
      handleLogout(authProvider);
    }

    if (err?.response?.status === 401) {
      handleLogout(authProvider);
    }

    if (err?.code === "ECONNABORTED") {
      return Promise.reject(err);
    }
    if (
      url.startsWith("/api/company/user-access/invitation/") ||
      url.startsWith("/api/ownership/stakeholder/invitation/") ||
      url.startsWith("/api/ownership/plan/rsa/invitation-details/") ||
      url.startsWith("/api/ownership/plan/stock-options/invitation-details/") ||
      url.startsWith("/api/ownership/ownership-plan/manager-signature-details/") ||
      url.startsWith("/api/ownership/stakeholder/accept-invitation") ||
      url.startsWith("/api/ownership/transaction/shares/details/") ||
      url.startsWith("/api/equity-management/issue-equity/warrant/details/") ||
      url.startsWith("/api/equity-management/issue-equity/warrant/details-settlement/") ||
      url.startsWith("/api/equity-management/issue-equity/convertible-loan/details/") ||
      url.startsWith("/api/equity-management/issue-equity/convertible-note/details/")
    ) {
      notify(
        translation("notify.invitationError"),
        false,
        "warning",
        undefined,
        true,
        "top-center",
        classes.invitationError
      );
      store.getActions().invitedUser.setInvitedUser(null);
    } else {
      if (!url.startsWith("/api/document/update-multiple-documents/")) {
        notify(translation("notify.somethingWentWrong"), true, "error");
      }
    }

    return Promise.reject(err);
  }
);
