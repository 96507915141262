import axios, { AxiosResponse } from "axios";

import { DividendsModes } from "../pages/report/aro-steps/dividends/types";
import {
  GetAroDetailsResponse,
  GetCompanyInformationResponse,
  GetDividendsResponse,
  GetFormTransactionsResponse,
  GetShareholdersAtYearEndResponse,
  GetShareholdersAtYearStartResponse,
  GetShareholdersResponse,
  GetTransactionsResponse,
  PostAltinnDetailsBody,
  PostAltinnDetailsResponse,
  PostCalculateDividendsBody,
  PostCalculateDividendsResponse,
  PostCompanyInformationBody,
  PostCompanyInformationResponse,
  PostDividendsBody,
  PostShareholdersIsinsBody,
  PostSmsBody,
  PostSmsResponse,
  PostTransactionsBody,
  PostTransactionsCheckResponse,
  PostTransactionsDoneBody,
  PostTransactionsModeBody,
} from "./types";

const apiBase = "api/aro";

export const aroService = {
  getAroDetails: async (companyId: string): Promise<GetAroDetailsResponse | null> => {
    try {
      const response = await axios.get<GetAroDetailsResponse>(`${apiBase}/details/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getCompanyInformation: async (companyId: string): Promise<GetCompanyInformationResponse | null> => {
    try {
      const response = await axios.get<GetCompanyInformationResponse>(`${apiBase}/company/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postCompanyInformation: async (body: PostCompanyInformationBody): Promise<PostCompanyInformationResponse | null> => {
    try {
      const response = await axios.post<PostCompanyInformationResponse>(`${apiBase}/company/details`, body);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postAltinRegisteredAccess: async (companyId: string, isRegistered: boolean): Promise<boolean> => {
    try {
      const response = await axios.post("/api/aro/altinn/registered-access", {
        companyId: Number(companyId),
        registeredAccessInAltinn: isRegistered,
      });

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },

  postSmsCode: async (body: PostSmsBody): Promise<AxiosResponse<PostSmsResponse> | null> => {
    try {
      return await axios.post<PostSmsResponse>("/api/aro/sms-code", body);
    } catch (e) {
      return null;
    }
  },

  postAltinnDetails: async (body: PostAltinnDetailsBody): Promise<AxiosResponse<PostAltinnDetailsResponse> | null> => {
    try {
      return await axios.post<PostAltinnDetailsResponse>("/api/aro/altinn/details", body);
    } catch (e) {
      return null;
    }
  },

  getShareholders: async (companyId: string): Promise<GetShareholdersResponse | null> => {
    try {
      const response = await axios.get<GetShareholdersResponse>(`${apiBase}/stakeholders/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getShareholdersAtYearStart: async (companyId: string): Promise<GetShareholdersAtYearStartResponse | null> => {
    try {
      const response = await axios.get<GetShareholdersAtYearStartResponse>(
        `${apiBase}/shareholders/last-year/${companyId}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getTransactions: async (companyId: string): Promise<GetTransactionsResponse | null> => {
    try {
      const response = await axios.get<GetTransactionsResponse>(`${apiBase}/transactions/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getFormTransactions: async (companyId: string): Promise<GetFormTransactionsResponse | null> => {
    try {
      const response = await axios.get<GetFormTransactionsResponse>(`${apiBase}/transactions/all/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postTransactions: async (body: PostTransactionsBody): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/transactions`, body);

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },

  postCheckTransactions: async (body: PostTransactionsBody): Promise<PostTransactionsCheckResponse> => {
    try {
      const response = await axios.post(`${apiBase}/transactions/check`, body);

      if (response.status === 200) {
        return response.data;
      }

      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    }
  },

  postTransactionsDone: async (body: PostTransactionsDoneBody): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/transactions/done`, body);

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },

  postTransactionsMode: async (body: PostTransactionsModeBody): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/transactions/transactions-mode`, body);

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },

  getShareholdersAtYearEnd: async (companyId: string): Promise<GetShareholdersAtYearEndResponse | null> => {
    try {
      const response = await axios.get<GetShareholdersAtYearEndResponse>(`${apiBase}/summary/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postShareholdersIsins: async (body: PostShareholdersIsinsBody): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/stakeholders`, body);

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },

  getDividends: async (companyId: string): Promise<GetDividendsResponse | null> => {
    try {
      const response = await axios.get<GetDividendsResponse>(`${apiBase}/dividends/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postCalculateDividends: async (body: PostCalculateDividendsBody): Promise<PostCalculateDividendsResponse | null> => {
    try {
      const response = await axios.post<PostCalculateDividendsResponse>(
        `${apiBase}/dividends/calculate-dividends`,
        body
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postDividends: async (body: PostDividendsBody): Promise<boolean> => {
    try {
      const response = await axios.post<boolean>(`${apiBase}/dividends`, body);

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },

  postDividendsMode: async (companyId: string, dividendMode: DividendsModes): Promise<boolean> => {
    try {
      const response = await axios.post<boolean>(`${apiBase}/dividends/dividend-mode`, { companyId, dividendMode });

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },

  postFinishAro: async (): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/TODO`);

      return response.status === 200;
    } catch (e) {
      return false;
    }
  },
};
