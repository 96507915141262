import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Formik, FormikConfig } from "formik";

import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../../../service/aro-service";
import { Shareholder, ShareholderEditFormValues } from "../../types";
import classes from "../styles.module.scss";
import Form from "./form";
import useEditShareholdersColumns from "./use-edit-shareholders-columns";
import useEditShareholdersForm from "./use-edit-shareholders-form";

type PT = {
  onClose(): void;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body.shareholdersEditForm");

const ShareholdersEditForm: FC<PT> = ({ onClose }) => {
  const { companyId } = useParams<{ companyId?: string }>();

  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);
  const getShareholdersAtYearEnd = useStoreActions((actions) => actions.aroModel.getShareholdersAtYearEndThunk);

  const [stakeholders, setStakeholders] = useState<Shareholder[]>([]);

  const { validationSchema, initialValues } = useEditShareholdersForm(stakeholders);

  const { columns } = useEditShareholdersColumns();

  const getShareholders = useCallback(async () => {
    try {
      const request = await axios.get(`/api/aro/stakeholders/${companyId}`);

      if (request.status === 200) {
        setStakeholders(request.data.stakeholders);
      }
    } catch (e) {
      console.error({ e });
    }
  }, [companyId]);

  const handleSubmit = useCallback<FormikConfig<ShareholderEditFormValues[]>["onSubmit"]>(
    async (values) => {
      try {
        if (companyId) {
          const shareholders = values.map((shareholder) => {
            return {
              stakeholderId: shareholder.stakeholderId,
              isCompanyOwned: shareholder.isCompanyOwned,
              shareholderName: shareholder.shareholderName,
              isin: shareholder.isin,
              address: shareholder.adresse,
              postalCode: shareholder.postnummer,
              postalCity: shareholder.poststed,
              countryId: shareholder.land,
            };
          });

          const result = await aroService.postShareholdersIsins({
            companyId: Number(companyId),
            stakeholders: shareholders,
          });

          if (result) {
            notify(t("successToast"), true, "success");
            getAroDetails(companyId);
            getShareholdersAtYearEnd(companyId);
            onClose();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, getAroDetails, getShareholdersAtYearEnd, onClose]
  );

  useEffect(() => {
    getShareholders();
  }, [getShareholders]);

  return (
    <WizardLayout className={classes.wizard}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form columns={columns} onClose={onClose} />
      </Formik>
    </WizardLayout>
  );
};

export default ShareholdersEditForm;
