import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import LinkButton from "common/components/atoms/LinkButton/LinkButton";
import Step from "common/components/atoms/Step/Step";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import Sidebar from "common/layout/MainLayout/Sidebar/Sidebar";
import {CONTACT_US_LINK} from "common/utils/constants";
import { Variant } from "store/GettingStartedGuideModel";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import StepFive from "./components/StepFive/StepFive";
import StepFour from "./components/StepFour/StepFour";
import StepOne from "./components/StepOne/StepOne";
import StepThree from "./components/StepThree/StepThree";
import StepTwo from "./components/StepTwo/StepTwo";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.gettingStartedGuide");

type StatusList = {
  stepOne: Variant;
  stepTwo: Variant;
  stepThree: Variant;
  stepFour: Variant;
  stepFive: Variant;
};

const calculateStatus = (previousStep: boolean, currentStep: boolean, nextStep = false): Variant => {
  if (nextStep) {
    return currentStep ? "done" : "undone";
  }
  if (!previousStep) {
    return currentStep ? "done" : "waiting";
  }
  return currentStep ? "done" : "progress";
};

const GettingStartedGuide: FC = () => {
  const { getStartedGuideThunk } = useStoreActions((actions) => actions.gettingStartedGuide);
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const guide = useStoreState((state) => state.gettingStartedGuide);
  const { user } = useStoreState((state) => state.account);

  const handleDashboard = useCallback(() => {
    navigate(getEMPath(["ownership", "capTable"]));
  }, [navigate]);

  const isSubscriptionActive = Boolean(user?.companies?.find((el) => el.id === Number(companyId))?.hasSubscription);

  const { status, allStepsAreDone }: { status: StatusList; allStepsAreDone: boolean } = useMemo(() => {
    const valuationAndShares = guide.valuationAndShares;
    const capTable = guide.capTable;
    const pools = guide.pools;
    const plans = guide.plansInPrograms;
    const numberOfGrantedPlans = guide.numberOfGrantedPlans;

    const stepOne = !!valuationAndShares.currentValuation;
    const stepTwo = !!capTable.numberOfShares && !!capTable.numberOfShareHolders;
    const stepThree = !!pools.length;
    const stepFour = !!plans.programs.length;
    const stepFive = !!numberOfGrantedPlans;

    const allStepsAreDone = stepOne && stepTwo && stepThree && stepFive && stepFour;

    return {
      status: {
        stepOne: calculateStatus(true, stepOne, stepTwo),
        stepTwo: calculateStatus(stepOne, stepTwo, stepThree),
        stepThree: calculateStatus(stepTwo, stepThree, stepFour),
        stepFour: calculateStatus(stepThree, stepFour, stepFive),
        stepFive: calculateStatus(stepFour, stepFive, false),
      },
      allStepsAreDone,
    };
  }, [guide]);

  useEffect(() => {
    if (companyId) {
      getStartedGuideThunk(+companyId);
    }
  }, [companyId, getStartedGuideThunk]);

  return (
    <>
      <PageContent className={classes.content} data-testid="getting-started-guide">
        <PageContent.Header>
          <PageContent.Header.Title>{t("title")}</PageContent.Header.Title>
        </PageContent.Header>
        <div className={classes.container}>
          <div className={classes.welcome}>{t("welcome", { name: user?.firstName })}</div>
          <div className={classNames("d-flex", classes.description)}>
            <p className="lh-3">{t("descriptionLeft")}</p>
            <p>{t("descriptionRight")}</p>
          </div>
          <Step>
            <StepOne status={status.stepOne} />
            <StepTwo status={status.stepTwo} />
            <StepThree status={!isSubscriptionActive ? "noSubscription" : status.stepThree} />
            <StepFour status={!isSubscriptionActive ? "noSubscription" : status.stepFour} />
            <StepFive status={!isSubscriptionActive ? "noSubscription" : status.stepFive} />
          </Step>

          {!isSubscriptionActive ? (
            <div className={classes.congrats}>
              <a href={CONTACT_US_LINK} target="_blank" rel="noreferrer">
                <Button size="s" variant="secondary">
                  {t("contactUs")}
                </Button>
              </a>
            </div>
          ) : allStepsAreDone ? (
            <div className={classes.congrats}>
              <b>{t("congratulations")}</b>
              <div className={classNames("mt-1", classes.congratsDescription)}>{t("congratulationsDescription")}</div>
              <Button onClick={handleDashboard} className="mt-2" size="s" variant="secondary">
                {t("goToCapTable")}
              </Button>
            </div>
          ) : null}
        </div>
      </PageContent>
      {/*TODO need add Icon when done with brand*/}
      <Sidebar style={{ display: "none" }}>
        <div className={classes.sidebar}>
          <div className={classes.content}></div>
          <div className={classes.footer}>
            <span>{t("needHelp")}</span> <LinkButton text={t("contactSupportHere")} path={"/"} />
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default GettingStartedGuide;
