import { useFormikContext } from "formik";

import { Helper, P, TextField } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { CompanyInformation } from "../types";
import { companyInformationFields as f } from "../use-company-information-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.companyInformation.body.contactPerson");

const ContactPerson = () => {
  const { values, errors, touched, handleChange } = useFormikContext<CompanyInformation>();

  return (
    <Helper>
      <div className="d-flex flex-column gap-3">
        <P.s color="foregroundMedium">{t("paragraph")}</P.s>
        <Helper.Question questionId={f.contactPersonName} type="input">
          <TextField
            label={t("contactPersonName")}
            name={f.contactPersonName}
            value={values[f.contactPersonName]}
            error={errors[f.contactPersonName]}
            isTouched={touched[f.contactPersonName]}
            onChange={handleChange}
          />
        </Helper.Question>
        <Helper.Answer answerId={f.contactPersonName}>{t("contactPersonNameAnswer")}</Helper.Answer>

        <Helper.Question questionId={f.contactPersonFirm} type="input">
          <TextField
            label={t("contactPersonFirm")}
            name={f.contactPersonFirm}
            value={values[f.contactPersonFirm]}
            error={errors[f.contactPersonFirm]}
            isTouched={touched[f.contactPersonFirm]}
            onChange={handleChange}
            isOptional
          />
        </Helper.Question>
        <Helper.Answer answerId={f.contactPersonFirm}>{t("contactPersonFirmAnswer")}</Helper.Answer>

        <Helper.Question questionId={f.contactPersonRole} type="input">
          <TextField
            label={t("contactPersonRole")}
            name={f.contactPersonRole}
            value={values[f.contactPersonRole]}
            error={errors[f.contactPersonRole]}
            isTouched={touched[f.contactPersonRole]}
            onChange={handleChange}
          />
        </Helper.Question>
        <Helper.Answer answerId={f.contactPersonRole}>{t("contactPersonRoleAnswer")}</Helper.Answer>

        <Helper.Question questionId={f.contactPersonEmail} type="input">
          <TextField
            label={t("contactPersonEmail")}
            name={f.contactPersonEmail}
            value={values[f.contactPersonEmail]}
            error={errors[f.contactPersonEmail]}
            isTouched={touched[f.contactPersonEmail]}
            onChange={handleChange}
          />
        </Helper.Question>
        <Helper.Answer answerId={f.contactPersonEmail}>{t("contactPersonEmailAnswer")}</Helper.Answer>

        <Helper.Question questionId={f.contactPersonPhone} type="input">
          <TextField
            type="number"
            label={t("contactPersonPhone")}
            name={f.contactPersonPhone}
            value={values[f.contactPersonPhone]}
            error={errors[f.contactPersonPhone]}
            isTouched={touched[f.contactPersonPhone]}
            onChange={handleChange}
            isOptional
          />
        </Helper.Question>
        <Helper.Answer answerId={f.contactPersonPhone}>{t("contactPersonPhoneAnswer")}</Helper.Answer>
      </div>
    </Helper>
  );
};

export default ContactPerson;
