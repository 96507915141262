import { FC, useCallback, useEffect, useState } from "react";
import {
  ColumnDef,
  FilterFnOption,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { FormikErrors, useFormikContext } from "formik";

import { ImportErrorHandler, ImportTable } from "common/components/atoms/ImportTable";

import { ShareholderEditFormValues } from "../../types";
import classes from "../styles.module.scss";

type OwnershipTableProps = {
  columns: ColumnDef<ShareholderEditFormValues>[];
};

const Table: FC<OwnershipTableProps> = ({ columns }) => {
  const { values, isSubmitting, isValid } = useFormikContext<ShareholderEditFormValues[]>();

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [listErrors, setListErrors] = useState<FormikErrors<ShareholderEditFormValues[]>>([]);

  const [globalFilter, setGlobalFilter] = useState("");

  const errorFilterData: FilterFnOption<ShareholderEditFormValues> = useCallback(
    (row: Row<ShareholderEditFormValues>) => {
      return !!listErrors[Number(row?.id)];
    },
    [listErrors]
  );

  useEffect(() => {
    if (isSubmitting) {
      setShowErrorMessage(!isValid);
    }
  }, [isSubmitting, isValid]);

  const table = useReactTable({
    data: values,
    columns,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,

    meta: { footerShow: true },
    state: {
      globalFilter: globalFilter,
    },
    globalFilterFn: errorFilterData,
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className={classNames("d-flex flex-column justify-content-center p-3 rounded-3", classes["table-wrapper"])}>
      {showErrorMessage && (
        <ImportErrorHandler
          updateErrorCallback={setListErrors}
          isFilterActive={!!globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {/* Note: tableType="founders" property needs to activate footer visibility */}
      <ImportTable table={table} tableType="founders" className="w-100 overflow-hidden" />
    </div>
  );
};
export default Table;
