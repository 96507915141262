import { useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { H, P } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../styles.module.scss";
import CalculateInputs from "./calculate-inputs/calculate-inputs";
import CalculatedTable from "./dividends-table/calculated/calculated-table";
import ManualTable from "./dividends-table/manual/manual-table";
import RadiobuttonsSection from "./radiobuttons-section/radiobuttons-section";
import { DividendsModes } from "./types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.body");

const DividendsBody = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const year = useStoreState((state) => state.aroModel.aroDetails?.year);
  const dividendMode = useStoreState((state) => state.aroModel.dividends?.dividendMode);
  const calculatedDividends = useStoreState((state) => state.aroModel.calculatedDividends);
  const getDividends = useStoreActions((actions) => actions.aroModel.getDividendsThunk);

  useEffect(() => {
    if (companyId) {
      getDividends(companyId);
    }
  }, [companyId, getDividends]);

  return (
    <div>
      <div className={cn(classes.backgroundElement, "rounded-3 p-3 mb-3")}>
        <H.xxs>{t("section1.title", { year })}</H.xxs>
        <P.s color="foregroundMedium">{t("section1.paragraph")}</P.s>
      </div>
      <div className={cn("py-2 px-3 rounded-3 mb-2", classes.backgroundElement)}>
        <RadiobuttonsSection className="mb-3" />

        {dividendMode === DividendsModes.AutomaticDividend && <CalculateInputs />}
        {dividendMode === DividendsModes.AutomaticDividend && !!calculatedDividends?.length && <CalculatedTable />}
        {dividendMode === DividendsModes.ManualDividend && <ManualTable />}
      </div>
    </div>
  );
};

export default DividendsBody;
