import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";

import { Dropdown, Tooltip } from "common/components/atoms";
import classes from "common/components/atoms/ImportTable/inputs/inputs.module.scss";
import { WarningExclamationMarkIcon } from "common/icons/svg";

import StakeholderFormSidebar from "../../../../../../../../stakeholders-management/components/stakeholder-form-sidebar";

type PropsTypes = {
  stakeholdersOptions: { id: number | string; name: string }[];
  rowIndex: number;
  columnId: string;
  refetchCallback: () => Promise<void>;
  createdStakeholderId: number | null;
  setCreatedStakeholderId: (id: number | null) => void;

  hasFromShareholder?: number[];
  dependOnField?: string;
  sourceOptions?: { id: number | string; name: string }[];
  placeholder?: string;
  isAroFlow?: boolean;
};

type FormikValues = {
  [key: string]: number | undefined;
};

const ShareholderField: FC<PropsTypes> = ({
  stakeholdersOptions,
  rowIndex,
  columnId,
  refetchCallback,
  createdStakeholderId,
  setCreatedStakeholderId,
  hasFromShareholder,
  dependOnField,
  sourceOptions,
  placeholder,
  isAroFlow,
}) => {
  const { values, handleChange, handleBlur, errors, setFieldValue, touched } = useFormikContext<FormikValues[]>();
  const [isSidebarFormOpen, setIsSidebarFormOpen] = useState(false);

  const handleCloseStakeholderSidebar = useCallback(() => {
    setIsSidebarFormOpen(false);
  }, []);

  const dependOnValue = dependOnField ? values[rowIndex]?.[dependOnField] || 0 : 0;

  const isStakeholder = useMemo(() => {
    return hasFromShareholder?.includes(dependOnValue);
  }, [dependOnValue, hasFromShareholder]);

  const handleSubmitStakeholder = useCallback(
    async (newStakeholderId?: number) => {
      if (newStakeholderId) {
        refetchCallback().then(() => {
          setCreatedStakeholderId(newStakeholderId);
        });
        setIsSidebarFormOpen(false);
      }
    },
    [refetchCallback, setCreatedStakeholderId]
  );

  const changeInterceptor = useCallback(
    (e: ChangeEvent<any>) => {
      if (e.target.value === -2) {
        setIsSidebarFormOpen(true);
      } else {
        handleChange(e);
      }
    },
    [setIsSidebarFormOpen, handleChange]
  );

  const error = errors[rowIndex]?.[columnId];
  const value = values[rowIndex]?.[columnId];
  const touchedValue = touched?.[rowIndex]?.[columnId];

  const data = useMemo(() => {
    if (isStakeholder || !sourceOptions) {
      return { options: stakeholdersOptions || [], isSearchable: true };
    } else {
      return { options: sourceOptions || [], isSearchable: false };
    }
  }, [isStakeholder, sourceOptions, stakeholdersOptions]);

  useEffect(() => {
    if (!createdStakeholderId) {
      if (!value) {
        setFieldValue(`[${rowIndex}].${columnId}`, -1);
      }
    }
  }, [columnId, rowIndex, setFieldValue, value, createdStakeholderId]);

  useEffect(() => {
    if (createdStakeholderId && stakeholdersOptions.find((option) => option.id === createdStakeholderId)) {
      setFieldValue(`[${rowIndex}].${columnId}`, createdStakeholderId);
      setCreatedStakeholderId(null);
    }
  }, [columnId, createdStakeholderId, stakeholdersOptions, rowIndex, setCreatedStakeholderId, setFieldValue]);

  return (
    <>
      {error && touchedValue && (
        <div className={classes.errorIcon}>
          <Tooltip className={classes.errorTooltip} popupContent={error}>
            <span>
              <WarningExclamationMarkIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <Dropdown
        name={`[${rowIndex}].${columnId}`}
        className={classes.selectInput}
        isSearchable={data.isSearchable}
        options={data.options}
        optionsIsObject
        selectedValue={value}
        onChange={changeInterceptor}
        onBlur={handleBlur}
        placeholder={placeholder}
      />

      {isSidebarFormOpen && (
        <StakeholderFormSidebar
          isOpen={isSidebarFormOpen}
          onClose={handleCloseStakeholderSidebar}
          onCreateSuccess={handleSubmitStakeholder}
          isAroFlow={isAroFlow}
        />
      )}
    </>
  );
};

export default ShareholderField;
