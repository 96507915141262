import { FC } from "react";
import { Accordion } from "react-bootstrap";

import { Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import { CheckIcon } from "common/icons/svg";
import { AroDetails } from "store/modelTypes/AroModel.type";
import { useStoreState } from "store/store";

import { AroSteps, AroStepsIds } from "../../types";
import BodyContainer from "./body-container/body-container";
import HeaderContainer from "./header-container";

type PT = {
  stepId: AroStepsIds;
};

const statusSelector = (stepId: AroStepsIds, details?: AroDetails) => {
  switch (stepId) {
    case AroSteps.CompanyInformation:
      return details?.companyInformationStatus;
    case AroSteps.CreateLink:
      return details?.altinnConnectionStatus;
    case AroSteps.ShareholdersAtYearStart:
      return details?.previousShareholdersStatus;
    case AroSteps.Transactions:
      return details?.transactionsStatus;
    case AroSteps.ShareholdersAtYearEnd:
      return details?.currentShareholdersStatus;
    case AroSteps.Dividends:
      return details?.dividendsStatus;
  }
};

const StepContainer: FC<PT> = ({ stepId }) => {
  const aroDetails = useStoreState((store) => store.aroModel.aroDetails);

  const status = statusSelector(stepId, aroDetails);

  return (
    <Step.Item id={stepId} variant="circle" status={!status || status === 1 ? "pending-transaction" : "done"}>
      <Step.Point
        variant="circle"
        icon={
          <Ui.xl bold className="d-flex justify-content-center w-100">
            {status === 2 || status === 3 ? <CheckIcon /> : stepId}
          </Ui.xl>
        }
      />
      <Accordion flush className="w-100 ms-3">
        <HeaderContainer stepId={stepId} />
        <Accordion.Collapse eventKey={stepId}>
          <BodyContainer stepId={stepId} />
        </Accordion.Collapse>
      </Accordion>
    </Step.Item>
  );
};

export default StepContainer;
