import { ChangeEvent, FC, memo, useCallback } from "react";
import { useFormikContext } from "formik";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import classes from "common/components/atoms/ImportTable/inputs/inputs.module.scss";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";
import { TransactionCategory, TransactionTypes } from "pages/equity-management/ownership/transactions/types";

import { fields } from "../use-edit-transactions-form";

type SourceFieldProps = {
  options: { id: number | string; name: string }[];
  rowIndex: number;
  columnId: string;
  isSearchable: boolean;
  placeholder?: string;
  isClearable?: boolean;
};

type FormikValues = {
  [key: string]: number | undefined;
};

const CategoryField: FC<SourceFieldProps> = memo(
  ({ rowIndex, columnId, isSearchable, options, placeholder, isClearable }) => {
    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = useFormikContext<FormikValues[]>();
    const error = errors[rowIndex]?.[columnId];
    const value = values[rowIndex]?.[columnId];
    const touchedValue = touched?.[rowIndex]?.[columnId];

    const handleChangeValue = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        handleChange(e);

        if (value !== Number(e.target.value)) {
          if (Number(e.target.value) === TransactionCategory.Issue) {
            console.log("issue");
            setFieldValue(`[${rowIndex}].${fields.fromStakeholderId}`, -1);
            setFieldValue(`[${rowIndex}].${fields.transactionTypeId}`, TransactionTypes.InvestorSharesIssued);
          }

          if (Number(e.target.value) === TransactionCategory.Sell) {
            console.log("sell");
            setFieldValue(`[${rowIndex}].${fields.transactionTypeId}`, TransactionTypes.BuySell);
          }
        }
      },
      [handleChange, rowIndex, setFieldValue, value]
    );

    return (
      <>
        {error && touchedValue && (
          <div className={classes.errorIcon}>
            <Tooltip className={classes.errorTooltip} popupContent={error}>
              <span>
                <WarningExclamationMarkIcon />
              </span>
            </Tooltip>
          </div>
        )}
        <Dropdown
          name={`[${rowIndex}].${columnId}`}
          className={classes.selectInput}
          isSearchable={isSearchable}
          options={options}
          optionsIsObject
          selectedValue={value}
          onChange={handleChangeValue}
          onBlur={handleBlur}
          placeholder={placeholder}
          isClearable={isClearable}
        />
      </>
    );
  }
);

export default CategoryField;
