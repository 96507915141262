import { TransactionCategoryIds, TransactionTypesIds } from "pages/equity-management/ownership/transactions/types";

export enum TransactionsMode {
  NoTransactions,
  ImportTransactions,
}

export type Transactions = {
  transactionsMode?: TransactionsMode;
  allTransactionsDone: boolean;
  transactions: Transaction[];
};

export type Transaction = {
  transactionId: number;
  transactedAt: string;
  categoryName: string;
  typeName: string;
  aroTransactionType: string;
  toStakeholderId: number;
  toIsCompanyOwned: true;
  toInitials: string;
  toShareholderName: string;
  toRepresentedBy: string;
  toAvatarFilePath: string;
  fromStakeholderId: number;
  fromIsCompanyOwned: true;
  fromInitials: string;
  fromShareholderName: string;
  fromRepresentedBy: string;
  fromAvatarFilePath: string;
  numberOfShares: number;
  nominalValue: number;
  sharePrice: number;
  sharePremium: number;
};

export type CheckTransaction = {
  transactionId: number;
  transactedAt: string;
  transactionTypeId: number;
  typeName: string;
  categoryName: string;
  categoryId: TransactionCategoryIds;
  numberOfShares: number;
  numberOfSharesBefore: number;
  fromName: string;
  fromRepresentativeName: string;
  fromInitials: string;
  fromAvatar: string;
  toName: string;
  toRepresentativeName: string;
  toInitials: string;
  toAvatar: string;
  toIsCompanyOwned: boolean;
  fromIsCompanyOwned: boolean;
  currencySymbol: string;
  transactionTotal: number;
  sharePrice: number;
  sharePriceBefore: number;
  shareClassName: string;
  description: string;
  multiplier: number;
  statusId: number;
  eventName: string;
  editedAfterConfimation: boolean;
  numbersOfSharesInCompany: number;
  documentStatusId: number;
  shareSeries: string;
  numberOfBundledTransactions: number;
  "transactionBundleId?": number;
  canEditConfirmed: boolean;
  files?: any[];
  numberOfFiles: number;
  transactionValidationStatus: number; // TODO
  transactionFailureReason: string;
};

export type PostFormTransaction = {
  transactionId?: number;
  transactedAt: string;
  transactionCategoryId: TransactionCategoryIds;
  transactionTypeId: TransactionTypesIds;
  toStakeholderId: number;
  fromStakeholderId?: number | null;
  numberOfShares: number;
  sharePrice: number;
  shareClassId: number;
};

export type GetFormTransaction = {
  transactionId: number;
  transactedAt: string;
  transactionCategoryId: TransactionCategoryIds;
  transactionTypeId: TransactionTypesIds;
  toStakeholderId: number;
  toIsCompanyOwned: boolean;
  toInitials: string;
  toShareholderName: string;
  toRepresentedBy: string;
  toAvatarFilePath: string;
  fromStakeholderId: number;
  fromIsCompanyOwned: boolean;
  fromInitials: string;
  fromShareholderName: string;
  fromRepresentedBy: string;
  fromAvatarFilePath: string;
  numberOfShares: number;
  sharePrice: number;
  nominalValue: number;
  totalPrice: number;
  shareClassId: number;
  isin: string;
};

export type TransactionCategoryDto = {
  transactionCategoryId: TransactionCategoryIds;
  transactionCategoryName: string;
};

export type IssueShareType = {
  transactionTypeId: TransactionTypesIds;
  transactionTypeName: string;
  aroTransactionType: string;
};
export type BuySellType = {
  transactionTypeId: TransactionTypesIds;
  transactionTypeName: string;
  aroTransactionType: string;
};

export type ShareClass = {
  id: number;
  name: string;
};

export type Stakeholder = {
  id: number;
  name: string;
};

export type FormTransactions = {
  transactions: GetFormTransaction[];
  transactionCategories: TransactionCategoryDto[];
  issueShareTypes: IssueShareType[];
  buySellTypes: BuySellType[];
  stakeholders: Stakeholder[];
  shareClasses: ShareClass[];
};
