import { sortingParams } from "common/types/Collapsible.types";

export enum ShareholdersTableKeys {
  shareholderName = "shareholderName",
  isin = "isin",
  address = "address",
  postCode = "postCode",
  city = "city",
  country = "country",
  shareClass = "shareClass",
  missingInfo = "missingInfo",
  edit = "edit",
  numberOfShares = "numberOfShares",
}

export type ShareholdersTableSorting = {
  field: ShareholdersTableKeys;
  sortDirection: sortingParams;
};

export type HeaderRow = {
  key: ShareholdersTableKeys;
  value: string;
  sortDisabled?: boolean;
};
