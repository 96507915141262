import { FC, useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { useFormikContext } from "formik";

import { TextInput } from "common/components/atoms/ImportTable";
import classes from "common/components/atoms/ImportTable/inputs/inputs.module.scss";
import useCurrencyById from "common/hooks/useCurrencyById";

import {
  TransactionCategory,
  TransactionTypes,
} from "../../../../../../../equity-management/ownership/transactions/types";
import { PostFormTransaction } from "../../types";
import { fields } from "../use-edit-transactions-form";

type PT = {
  row: Row<PostFormTransaction>;
  rowIndex: number;
  columnId: string;
  currencyId?: number;
};

type FormikValues = {
  [key: string]: number | undefined;
};

const SourceField: FC<PT> = ({ row, rowIndex, columnId, currencyId }) => {
  const { setFieldValue } = useFormikContext<FormikValues[]>();
  const { currencySymbol } = useCurrencyById(currencyId);
  const [isDisabled, setIsDisabled] = useState(false);

  const selectedCategoryId = row.original[fields.transactionCategoryId];
  const selectedTypeId = row.original[fields.transactionTypeId];

  useEffect(() => {
    if (selectedCategoryId === TransactionCategory.Sell) {
      if (
        selectedTypeId === TransactionTypes.Gift ||
        selectedTypeId === TransactionTypes.GiftWithoutTaxContinuity ||
        selectedTypeId === TransactionTypes.Inheritance ||
        selectedTypeId === TransactionTypes.InheritanceWithoutTaxContinuity
      ) {
        setIsDisabled(true);
        setFieldValue(`[${rowIndex}].${columnId}`, 0);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(false);
    }
  }, [columnId, row, rowIndex, selectedCategoryId, selectedTypeId, setFieldValue]);

  return (
    <TextInput
      rowIndex={rowIndex}
      columnId={columnId}
      className={classes.shareClassAlign}
      type="number"
      iconRight={currencySymbol}
      isDisabled={isDisabled}
    />
  );
};

export default SourceField;
