import { useMemo } from "react";

import StakeholderAddressField from "../fields/StakeholderAddressField";
import StakeholderCompanyNameField from "../fields/StakeholderCompanyNameField";
import StakeholderCountryField from "../fields/StakeholderCountryField";
import StakeholderEmailField from "../fields/StakeholderEmailField";
import StakeholderFirstNameField from "../fields/StakeholderFirstNameField";
import StakeholderLastNameField from "../fields/StakeholderLastNameField";
import StakeholderOrgNumberField from "../fields/StakeholderOrgNumberField";
import StakeholderPhoneNumberField from "../fields/StakeholderPhoneNumberField";
import StakeholderPostAddressField from "../fields/StakeholderPostAddressField";
import StakeholderRelationField from "../fields/StakeholderRelationField";
import { StakeholderField, StakeholderFieldsParamsType } from "../Stakeholder.types";

const useCompany = (updatedFields?: StakeholderFieldsParamsType, isAroFlow?: boolean) => {
  return useMemo<{ topFields: StakeholderField[]; otherFields: StakeholderField[] }>(() => {
    const fields: StakeholderField[] = [
      {
        key: "companyName",
        Component: StakeholderCompanyNameField,
        meta: {},
        isTopField: true,
      },
      {
        key: "relationshipTypeId",
        Component: StakeholderRelationField,
        meta: { isOptional: false },
        isTopField: true,
      },
      {
        key: "organizationNumber",
        Component: StakeholderOrgNumberField,
        meta: { isOptional: !isAroFlow },
        isTopField: true,
      },
      {
        key: "firstName",
        Component: StakeholderFirstNameField,
        meta: {},
        isTopField: true,
      },
      {
        key: "lastName",
        Component: StakeholderLastNameField,
        meta: {},
        isTopField: true,
      },
      {
        key: "email",
        Component: StakeholderEmailField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "phoneNumber",
        Component: StakeholderPhoneNumberField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "countryId",
        Component: StakeholderCountryField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "address",
        Component: StakeholderAddressField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "businessPostAddress",
        Component: StakeholderPostAddressField,
        meta: { isOptional: true },
        isTopField: false,
      },
    ];

    const listOfFields = updatedFields ? updatedFields : {};

    const combinedFields = fields.map((field) => ({ ...field, ...listOfFields[field.key] }));

    const topFields = combinedFields.filter((field) => field.isTopField);
    const otherFields = combinedFields.filter((field) => !field.isTopField);

    return { topFields, otherFields };
  }, [updatedFields]);
};

export default useCompany;
