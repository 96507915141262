import React from "react";
import { useAccordionButton } from "react-bootstrap";

import { P, Tag, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import ProgrammaticAccordionToggle from "pages/equity-management/ownership/transactions/components/transactions-list/programmatic-accordion-toggle/programmatic-accordion-toggle";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { checkAroEnableStep } from "../../aro-toast-warning/aro-toast-warning";
import { StatusTagVariantMapper } from "../../main";
import { AroSteps } from "../../types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.createLink.header");

const CreateLinkHeader = () => {
  const decoratedOnClick = useAccordionButton(AroSteps.CreateLink);
  const aroDetails = useStoreState((store) => store.aroModel.aroDetails);
  const isLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);

  const isStepActive =
    aroDetails?.companyInformationStatus === AroSectionStatuses.Utfylt ||
    aroDetails?.companyInformationStatus === AroSectionStatuses.Forhåndsutfylt;

  return (
    <div
      onClick={checkAroEnableStep(isStepActive, decoratedOnClick)}
      className="d-flex justify-content-between py-2 px-3"
    >
      <div>
        <Ui.xl bold>{t("title")}</Ui.xl>
        <P.s color="foregroundMedium">{t("subtitle")}</P.s>
      </div>

      <div className="d-flex align-items-center gap-2">
        <Tag variant={StatusTagVariantMapper[aroDetails?.altinnConnectionStatus || 0]}>
          <Skeleton isLoading={isLoading}>{AroSectionStatuses[aroDetails?.altinnConnectionStatus || 0]}</Skeleton>
        </Tag>
        <ProgrammaticAccordionToggle eventKey={AroSteps.CompanyInformation} itemIdToOpen={null} />
      </div>
    </div>
  );
};

export default CreateLinkHeader;
