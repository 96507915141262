import { FC } from "react";

import { Ui } from "common/components/atoms";

import { HeadColumn } from "./types";

type PT = {
  columns: HeadColumn[];
};

const DividendsTableHeader: FC<PT> = ({ columns }) => {
  return (
    <thead>
      <tr>
        {columns.map((el) => (
          <th key={el.key}>
            <div className="d-flex justify-content-start">
              <Ui.xs>{el.value}</Ui.xs>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default DividendsTableHeader;
