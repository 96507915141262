import React, { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import classNames from "classnames";
import { assoc, isNil, update } from "ramda";

import { Banner, Button, Check, LinkHelpText, P, Ui } from "common/components/atoms";
import { ImportTable } from "common/components/atoms/ImportTable";
import { AltinnOnlyIcon, UnlistedIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../styles.module.scss";
import { fields } from "../shareholders-at-year-end/shareholders-edit-form/use-edit-shareholders-form";
import { AroShareholder, ConflictedShareholders } from "../types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearStart.body.conflicts");

type DataProps = AroShareholder & { isSelected?: boolean };

type InternalProps = {
  altinnShareholder: DataProps;
  unlistedShareholder: DataProps;
  aroShareholderId: number;
}[];

const ShareholdersConflicts: FC<{ data: ConflictedShareholders }> = ({ data }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const getShareholderAtYearStart = useStoreActions((store) => store.aroModel.getShareholdersAtYearStartThunk);

  const initialData = data.map((el) => ({
    altinnShareholder: { ...el.altinnShareholder, isSelected: true },
    unlistedShareholder: { ...el.unlistedShareholder, isSelected: false },
    aroShareholderId: el.aroShareholderId,
  }));

  const [dataValues, setDataValues] = useState<InternalProps>(initialData as unknown as InternalProps);

  const handleSubmit = async () => {
    try {
      const dataToSend = dataValues.map((el) => {
        if (el.unlistedShareholder.isSelected) {
          return {
            aroShareholderId: el.aroShareholderId,
            useUnlistedData: true,
          };
        }

        return {
          aroShareholderId: el.aroShareholderId,
          useUnlistedData: false,
        };
      });

      const request = await axios.post("/api/aro/shareholder-conflict/with-match", {
        companyId,
        conflicts: dataToSend,
      });

      if (request.status === 200) {
        getShareholderAtYearStart(String(companyId));
      }
    } catch (e) {
      console.error({ e });
    }
  };

  const columns = useMemo(() => {
    const cells: ColumnDef<InternalProps[number]>[] = [
      {
        header: t("tableColumns.shareholder"),
        accessorKey: "shareholder",
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            const isUnlisted = unlistedItem === el;
            const isDisabled = !isNil(el.isSelected) && !el.isSelected;

            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: isDisabled,
                })}
              >
                <Check
                  type="radio"
                  checked={el.isSelected}
                  onChange={(e) => {
                    let dataToUpdate: InternalProps[0];

                    if (index === 0) {
                      dataToUpdate = {
                        unlistedShareholder: assoc("isSelected", e.target.checked, unlistedItem),
                        altinnShareholder: assoc("isSelected", !e.target.checked, altinnItem),
                        aroShareholderId: dataValues[props.row.index].aroShareholderId,
                      };
                    } else {
                      dataToUpdate = {
                        unlistedShareholder: assoc("isSelected", !e.target.checked, unlistedItem),
                        altinnShareholder: assoc("isSelected", e.target.checked, altinnItem),
                        aroShareholderId: dataValues[props.row.index].aroShareholderId,
                      };
                    }

                    const updatedValues = update(props.row.index, dataToUpdate, dataValues);

                    setDataValues(updatedValues);
                  }}
                />

                <LinkHelpText
                  title=""
                  overlayClass={classes["tooltip"]}
                  content={t(isUnlisted ? "tableColumns.dataFromUnlisted" : "tableColumns.dataFromAltinn")}
                  placement="top"
                >
                  <div
                    className={classNames("ms-2 me-1_half", classes["avatar-card"], {
                      [classes["altinn"]]: !isUnlisted,
                    })}
                  >
                    {isUnlisted ? <UnlistedIcon /> : <AltinnOnlyIcon />}
                  </div>
                </LinkHelpText>
                <Ui.s bold={!isDisabled}>{el.name || "Default name"}</Ui.s>
              </div>
            );
          });
        },
        minSize: 200,
      },
      {
        header: t("tableColumns.isin"),
        accessorKey: fields.isin,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            const isDisabled = !isNil(el.isSelected) && !el.isSelected;

            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: isDisabled,
                })}
              >
                {el.organizationNumber}
              </div>
            );
          });
        },
        minSize: 140,
      },
      {
        header: t("tableColumns.address"),
        accessorKey: fields.adresse,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            const isDisabled = !isNil(el.isSelected) && !el.isSelected;

            return (
              <div
                key={`${props.row.index}-${index}`}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: isDisabled,
                })}
              >
                {el.address}
              </div>
            );
          });
        },
        minSize: 180,
      },
      {
        header: t("tableColumns.postNumber"),
        accessorKey: fields.postnummer,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            const isDisabled = !isNil(el.isSelected) && !el.isSelected;

            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: isDisabled,
                })}
              >
                {el.postalCode}
              </div>
            );
          });
        },
        minSize: 120,
      },
      {
        header: t("tableColumns.postSted"),
        accessorKey: fields.poststed,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            const isDisabled = !isNil(el.isSelected) && !el.isSelected;

            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: isDisabled,
                })}
              >
                {el.postalCity}
              </div>
            );
          });
        },
        minSize: 120,
      },
    ];

    return cells;
  }, [dataValues]);

  const table = useReactTable({
    columns,
    data: dataValues,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    meta: { footerShow: false },
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className={classNames(classes.backgroundElement, "mb-1_half rounded-3 p-3 cursor-pointer")}>
      <Banner variant="warning" className="align-items-center">
        <P.s className="mx-2" color="foregroundHigh">
          {t("informationNeedConfirmed", {
            amount: data.length,
          })}
        </P.s>
      </Banner>
      <div className="mt-4 d-flex align-items-center">
        <div>
          <Ui.m bold className="d-flex align-items-center">
            {t("shareholdersWithConflicts")}
            <span className={classes["counter"]}>
              <Ui.xs color="foregroundLow">{data.length}</Ui.xs>
            </span>
          </Ui.m>
          <P.s color="foregroundMedium">{t("selectWhoseDataUpdate")}</P.s>
        </div>

        <Button className="ms-auto" onClick={handleSubmit}>
          {t("updateSelectedRows")}
        </Button>
      </div>

      <div className="my-3" style={{ height: 1, backgroundColor: scssVariables.strokeMedium }} />

      <ImportTable table={table} className="w-100 overflow-hidden" />
    </div>
  );
};

export default ShareholdersConflicts;
