import { useCallback } from "react";
import axios from "axios";

import { UploadedFile } from "../components/atoms/FileUploader/FileUploader";
import { UploadFileTypes } from "../enums/enum";
import { notify } from "../utils/notify/notifyFunction";

const useUpdateCompanyDocuments = () => {
  const updateCompanyDocuments = useCallback(
    async (
      companyId: string | number,
      entityId: number,
      companyFileType: UploadFileTypes,
      newFiles: File[] = [],
      oldFiles: UploadedFile[] = [],
      documentStatusId?: number
    ) => {
      try {
        const formData = new FormData();
        formData.append("companyId", companyId.toString());
        formData.append("entityId", entityId.toString());
        formData.append("companyFileType", companyFileType.toString());
        if (documentStatusId) {
          formData.append("documentStatusId", documentStatusId?.toString());
        }
        newFiles.forEach((el) => formData.append("Files", el));
        oldFiles.forEach((el) => formData.append("OldFileIds", el.fileId.toString()));

        await axios.post("/api/document/update-multiple-documents", formData);
      } catch (error) {
        notify("Files were not uploaded", true, "error");
        await Promise.reject(error);
      }
    },
    []
  );

  return { updateCompanyDocuments };
};

export default useUpdateCompanyDocuments;
