import { FC, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { format } from "date-fns";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button, P, Ui } from "common/components/atoms";
import { dateFormatWithoutTime } from "common/components/atoms/DatePicker/DatePicker";
import CTable from "common/components/atoms/Table/Table";
import { PlusIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../../../service/aro-service";
import { PostDividendsBody } from "../../../../../../service/types";
import { HeadColumn } from "../../../shareholders/shareholders-at-year-end/share-classes/shareclass-table/types";
import classesBg from "../../../styles.module.scss";
import { getDefaultShareClass } from "../../../transactions/edit-transactions-table/use-edit-transactions-form";
import { StakeholderDividendForm } from "../../types";
import DividendsTableHeader from "../dividends-table-header";
import ManualTableBody from "./manual-table-body";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.body.table");
const tV = createTranslation(TranslationNS.validation);

const validationSchema = Yup.object({
  stakeholders: Yup.array().of(
    Yup.object({
      stakeholderId: Yup.number()
        .moreThan(0, () => tV("required"))
        .required(() => tV("required")),
      dividendAmount: Yup.number()
        .moreThan(0, () => tV("minNumberMore", { number: 0 }))
        .required(() => tV("required")),
      dividendDate: Yup.string().required(() => tV("required")),
      shareClassId: Yup.number()
        .moreThan(0, () => tV("required"))
        .required(() => tV("required")),
    })
  ),
});

const ManualTable: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const dividends = useStoreState((state) => state.aroModel.dividends?.stakeholderDividends);
  const shareClasses = useStoreState((state) => state.aroModel.shareholdersAtYearEnd?.shareClasses);
  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);
  const getShareholdersAtYearEnd = useStoreActions((actions) => actions.aroModel.getShareholdersAtYearEndThunk);

  useEffect(() => {
    if (!shareClasses && companyId) {
      getShareholdersAtYearEnd(companyId);
    }
  }, [companyId, getShareholdersAtYearEnd, shareClasses]);

  const headColumns: HeadColumn[] = useMemo(
    () => [
      {
        key: "shareholder",
        value: t("stakeholder"),
      },
      {
        key: "date",
        value: t("date"),
      },
      {
        key: "dividends",
        value: t("dividends"),
      },
      {
        key: "shareClass",
        value: t("shareClass"),
      },
      {
        key: "empty",
        value: "",
      },
    ],
    []
  );

  const createEmptyStakeholder = useCallback(
    () => ({
      key: Math.random(),
      stakeholderId: 0,
      dividendDate: "",
      dividendAmount: 0,
      shareClassId: getDefaultShareClass(shareClasses || []),
    }),
    [shareClasses]
  );

  const dividendsTransformed: StakeholderDividendForm[] | undefined = useMemo(() => {
    const transformed = dividends?.map((dividend) => ({
      key: Math.random(),
      stakeholderId: dividend.stakeholderId,
      dividendDate: format(new Date(dividend.dividendDate), dateFormatWithoutTime),
      dividendAmount: dividend.dividendAmount,
      shareClassId: dividend.shareClassId,
    }));

    if (!transformed?.length) {
      return [createEmptyStakeholder()];
    }

    return transformed;
  }, [createEmptyStakeholder, dividends]);

  const initialValues: { stakeholders: StakeholderDividendForm[] } = useMemo(
    () => ({ stakeholders: dividendsTransformed }),
    [dividendsTransformed]
  );

  const handleSubmit = useCallback(
    async (values: { stakeholders: StakeholderDividendForm[] }) => {
      if (companyId) {
        const body: PostDividendsBody = {
          companyId: companyId,
          stakeholderDividends: values.stakeholders.map((s) => {
            const { key, ...otherValues } = s;
            return otherValues;
          }),
        };

        const result = await aroService.postDividends(body);

        if (result) {
          notify(t("successToast"), true, "success");
          await getAroDetails(companyId);
        }
      }
    },
    [companyId, getAroDetails]
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, isSubmitting, submitForm, setFieldValue }) => {
        const addRow = () => {
          setFieldValue(`stakeholders[${String(values.stakeholders.length)}]`, createEmptyStakeholder());
        };

        return (
          <>
            <CTable
              headComponent={<DividendsTableHeader columns={headColumns} />}
              bodyComponent={<ManualTableBody />}
            />
            <Button className="mt-4" iconLeft={<PlusIcon />} variant="tertiary" onClick={addRow}>
              {t("addRow")}
            </Button>
            <div className={cn(classesBg.backgroundInfo, "d-flex justify-content-between rounded-3 py-2 px-3 mt-3")}>
              <div>
                <Ui.m bold>{t("submitPlate.title")}</Ui.m>
                <P.s>{t("submitPlate.paragraph")}</P.s>
              </div>
              <Button variant="primaryInformation" isLoading={isSubmitting} onClick={submitForm}>
                {t("submitPlate.submit")}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ManualTable;
