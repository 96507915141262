import { FC } from "react";
import cn from "classnames";

import { Button, H, ModalInfo, Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import { WarningIcon } from "common/icons/svg";
import CapitalIncreaseHeader from "pages/equity-management/ownership/transactions/components/transactions-list/transaction-item/transaction-headers/capital-inscrease-header/capital-increase-header";
import IssueSharesAndBuySellHeader from "pages/equity-management/ownership/transactions/components/transactions-list/transaction-item/transaction-headers/issue-shares-and-buy-sell-header";
import {
  Transaction,
  TransactionCategory,
  TransactionStatus,
} from "pages/equity-management/ownership/transactions/types";
import { createTranslation, TranslationNS } from "translation";

import { CheckTransaction } from "../types";
import classes from "./styles.module.scss";

type PropsTypes = {
  transactions: CheckTransaction[];
  onClose: () => void;
};

const t = createTranslation(
  TranslationNS.pages,
  "aro.main.steps.transactions.body.editTransactionsTable.dependentTransactions"
);

const DependentTransactions: FC<PropsTypes> = ({ transactions, onClose }) => {
  return (
    <ModalInfo show={true} handleClose={onClose} className="d-flex flex-column" contentClassName="text-start" size="lg">
      <H.m className="mb-2">{t("title")}</H.m>
      <div className={cn(classes.darkBackground, "rounded-2 d-flex flex-column gap-2 p-3 mb-3")}>
        {transactions.map((transaction) => {
          const isIssueOrBuySell =
            transaction.categoryId === TransactionCategory.Issue || transaction.categoryId === TransactionCategory.Sell;

          const isBundle = transaction.categoryId === TransactionCategory.CapitalIncrease;

          return (
            <Step.Item
              key={transaction.transactionId}
              id={String(transaction.transactionId)}
              className="d-block p-0"
              status={
                transaction.statusId === TransactionStatus.Pending ? "pending-transaction" : "committed-transaction"
              }
            >
              <div className={cn(classes.lightBackground, "py-2 px-3")}>
                {isIssueOrBuySell && (
                  <>
                    <IssueSharesAndBuySellHeader
                      transaction={transaction as unknown as Transaction}
                      isMini
                      className="d-flex flex-column mb-2"
                    />
                    <div className={cn(classes.redPlate, "p-1 d-flex rounded-2")}>
                      <WarningIcon fontSize="20" />
                      <Ui.xs className="ms-1" color="foregroundHigh">
                        {transaction.transactionFailureReason}
                      </Ui.xs>
                    </div>
                  </>
                )}
                {isBundle && (
                  <>
                    <CapitalIncreaseHeader transaction={transaction as unknown as Transaction} />
                    <div className={cn(classes.redPlate, "p-1 d-flex mt-2")}>
                      <WarningIcon fontSize="20" />
                      <Ui.xs className="ms-1" color="foregroundHigh">
                        {transaction.transactionFailureReason}
                      </Ui.xs>
                    </div>
                  </>
                )}
              </div>
            </Step.Item>
          );
        })}
      </div>
      <div className="d-flex justify-content-end">
        <Button onClick={onClose}>{t("backBtn")}</Button>
      </div>
    </ModalInfo>
  );
};

export default DependentTransactions;
