import React, { FC, useMemo, useState } from "react";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

import { Button, LinkHelpText, P, Ui } from "common/components/atoms";
import { ImportTable } from "common/components/atoms/ImportTable";
import { AltinnOnlyIcon, UnlistedIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../styles.module.scss";
import { fields } from "../shareholders-at-year-end/shareholders-edit-form/use-edit-shareholders-form";
import { AroShareholder, ConflictedShareholders } from "../types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearStart.body");

type DataProps = AroShareholder & { selectedId?: number };

type InternalProps = {
  altinnShareholder: DataProps;
  unlistedShareholder: DataProps;
}[];

const ShareholdersUnmatched: FC<{ data: ConflictedShareholders }> = ({ data }) => {
  const initialData = data.map((el) => ({
    altinnShareholder: { ...el.altinnShareholder, selectedId: undefined },
    unlistedShareholder: { ...el.unlistedShareholder, selectedId: undefined },
  }));

  const [dataValues, setDataValues] = useState<InternalProps>(initialData as unknown as InternalProps);

  const columns = useMemo(() => {
    const cells: ColumnDef<InternalProps[number]>[] = [
      {
        header: t("conflicts.tableColumns.shareholder"),
        accessorKey: "shareholder",
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            const isUnlisted = unlistedItem === el;

            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                <LinkHelpText
                  title=""
                  overlayClass={classes["tooltip"]}
                  content={t(isUnlisted ? "dataFromUnlisted" : "dataFromAltinn")}
                  placement="top"
                >
                  <div
                    className={classNames("ms-2 me-1_half", classes["avatar-card"], {
                      [classes["altinn"]]: !isUnlisted,
                    })}
                  >
                    {isUnlisted ? <UnlistedIcon /> : <AltinnOnlyIcon />}
                  </div>
                </LinkHelpText>

                <Ui.s>{el.name || "Default name"}</Ui.s>
              </div>
            );
          });
        },
        minSize: 200,
      },
      {
        header: t("conflicts.tableColumns.isin"),
        accessorKey: fields.isin,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {el.organizationNumber}
              </div>
            );
          });
        },
        minSize: 140,
      },
      {
        header: t("conflicts.tableColumns.address"),
        accessorKey: fields.adresse,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            return (
              <div
                key={`${props.row.index}-${index}`}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {el.address}
              </div>
            );
          });
        },
        minSize: 180,
      },
      {
        header: t("conflicts.tableColumns.postNumber"),
        accessorKey: fields.postnummer,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {el.postalCode}
              </div>
            );
          });
        },
        minSize: 120,
      },
      {
        header: t("conflicts.tableColumns.postSted"),
        accessorKey: fields.poststed,
        cell: (props) => {
          const [unlistedItem, altinnItem]: [DataProps, DataProps] = [
            props.row.original["unlistedShareholder"],
            props.row.original["altinnShareholder"],
          ];

          return [unlistedItem, altinnItem].map((el, index) => {
            return (
              <div
                key={index}
                className={classNames("d-flex align-items-center", classes["row"], {
                  [classes["disabled"]]: false,
                })}
              >
                {el.postalCity}
              </div>
            );
          });
        },
        minSize: 120,
      },
    ];

    return cells;
  }, []);

  const table = useReactTable({
    columns,
    data: dataValues,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    meta: { footerShow: false },
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className={classNames(classes.backgroundElement, "mb-1_half rounded-3 p-3 cursor-pointer")}>
      <div className="d-flex justify-content-between align-items-center mb-semihalf">
        <P.s className="w-75">{t("shareholdersUnableToMatch")}</P.s>
        <Button>Large</Button>
      </div>

      <ImportTable table={table} className="w-100 overflow-hidden" />

      {/*<div><WarningIcon /> <Ui.xs>{t("noMatchesFound")}</Ui.xs></div>*/}
    </div>
  );
};

export default ShareholdersUnmatched;
