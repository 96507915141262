import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { defaultTo } from "ramda";

import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { Transaction, TransactionCategory } from "../../../types";
import formatTransactionDate from "../../format-transaction-date";
import classes from "./manage-documents-panel.module.scss";

type Props = {
  transaction: Transaction | null;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.manageDocuments");

const ManageDocumentsDetails: FC<Props> = ({ transaction }) => {
  const fNumber = useFormatNumbers(transaction?.currencySymbol);

  const topSectionTitle = useMemo(() => {
    if (transaction?.categoryId === TransactionCategory.ChangeNominalValue) {
      const isDecreased = defaultTo(0, transaction?.sharePriceBefore) > defaultTo(0, transaction?.sharePrice);

      return isDecreased ? "Nominal value decreased" : "Nominal value increased";
    }

    if (transaction?.categoryId === TransactionCategory.Issue) {
      return transaction?.toName;
    }
    if (transaction?.categoryId === TransactionCategory.CapitalIncrease) {
      return transaction?.eventName;
    }

    if (transaction?.categoryId === TransactionCategory.Sell) {
      return `${transaction?.fromName || "..."}  ->  ${transaction?.toName || "..."}`;
    }

    if (transaction?.categoryId === TransactionCategory.Split) {
      return (
        <>
          <Ui.m>{fNumber(transaction?.numberOfSharesBefore, "amount")}</Ui.m>
          <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundHigh} />
          <Ui.m bold className="ms-half">{`${fNumber(transaction?.numberOfShares, "amount")} shares • ${
            transaction?.categoryName
          } 1:${fNumber(transaction?.multiplier, Number.isInteger(transaction?.multiplier) ? "amount" : "unitPrice", {
            hideCurrencySymbol: true,
          })}`}</Ui.m>
        </>
      );
    }
  }, [
    fNumber,
    transaction?.categoryId,
    transaction?.categoryName,
    transaction?.eventName,
    transaction?.fromName,
    transaction?.multiplier,
    transaction?.numberOfShares,
    transaction?.numberOfSharesBefore,
    transaction?.sharePrice,
    transaction?.sharePriceBefore,
    transaction?.toName,
  ]);

  const isCapitalIncreaseType = transaction?.categoryId === TransactionCategory.CapitalIncrease;

  return (
    <div className={classNames("mt-7", classes["info"])}>
      <Ui.s bold className="mb-1 d-flex align-items-center">
        {topSectionTitle}
      </Ui.s>

      <div className="d-flex align-items-center">
        <Tag size="s" variant="complete">
          {transaction?.categoryName}
        </Tag>
        {isCapitalIncreaseType ? (
          <>
            <Ui.xs color="foregroundLow" className="ms-2">
              {transaction?.transactedAt ? formatTransactionDate(defaultTo("", transaction?.transactedAt)) : null}
            </Ui.xs>
            <Ui.xs color="foregroundLow" className="mx-1">
              •
            </Ui.xs>
            <Ui.xs color="foregroundLow">
              {fNumber(transaction?.numberOfShares, "amount")} {t("totalShares")}
            </Ui.xs>
          </>
        ) : (
          <>
            <Ui.xs color="foregroundLow" className="ms-2">
              {transaction?.transactedAt ? formatTransactionDate(defaultTo("", transaction?.transactedAt)) : null}
            </Ui.xs>
            <Ui.xs color="foregroundLow" className="mx-1">
              •
            </Ui.xs>
            <Ui.xs color="foregroundLow">
              {transaction?.categoryId === TransactionCategory.Split ? (
                <>
                  {`${fNumber(transaction?.sharePriceBefore, "sharePrice")}/share`}
                  <ArrowRightIcon className="mx-half" />
                  {`${fNumber(transaction?.sharePrice, "sharePrice")}/share`}
                </>
              ) : transaction?.categoryId === TransactionCategory.ChangeNominalValue ? (
                <>
                  {fNumber(transaction?.sharePriceBefore, "sharePrice")}
                  <ArrowRightIcon className="mx-half" />
                  {fNumber(transaction?.sharePrice, "sharePrice")}
                </>
              ) : (
                `${fNumber(transaction?.transactionTotal, "value")} (${fNumber(
                  transaction?.sharePrice,
                  "sharePrice"
                )}/share)`
              )}
            </Ui.xs>
            <Ui.xs color="foregroundLow" className="mx-1">
              •
            </Ui.xs>
            <Ui.xs color="foregroundLow">
              {fNumber(transaction?.numberOfShares, "amount")} {t("totalShares")}
            </Ui.xs>
          </>
        )}
      </div>
    </div>
  );
};

export default ManageDocumentsDetails;
