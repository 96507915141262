import { FC } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useFormikContext } from "formik";

import { Button, Ui } from "common/components/atoms";
import { ArrowLeftIcon } from "common/icons/svg";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { createTranslation, TranslationNS } from "translation";

import CloseConfirm from "../../../common/close-confirm";
import { ShareholderEditFormValues } from "../../types";
import Table from "./table";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body.shareholdersEditForm");

type PT = {
  columns: ColumnDef<ShareholderEditFormValues>[];
  onClose(): void;
};

const Form: FC<PT> = ({ columns, onClose }) => {
  const { isSubmitting, submitForm } = useFormikContext<ShareholderEditFormValues[]>();
  const setCloseConfirmOpenAction = WizardLayoutStore.useStoreActions((actions) => actions.setCloseConfirmOpen);

  return (
    <>
      <WizardLayout.Header title={t("title")} />
      <div className="d-flex justify-content-between align-items-center px-7">
        <Button
          onClick={() => setCloseConfirmOpenAction(true)}
          isOnlyIcon
          isDisabled={isSubmitting}
          variant="secondary"
        >
          <ArrowLeftIcon />
        </Button>
        <Ui.l>{t("paragraph")}</Ui.l>
        <Button isLoading={isSubmitting} onClick={submitForm}>
          {t("submit")}
        </Button>
      </div>
      <WizardContent hideSteps style={{ maxWidth: "100%" }}>
        <Table columns={columns} />
      </WizardContent>
      <CloseConfirm handleClose={onClose} />
    </>
  );
};

export default Form;
