import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button, P, Ui } from "common/components/atoms";
import CTable from "common/components/atoms/Table/Table";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../../../service/aro-service";
import { PostDividendsBody } from "../../../../../../service/types";
import { HeadColumn } from "../../../shareholders/shareholders-at-year-end/share-classes/shareclass-table/types";
import classesBg from "../../../styles.module.scss";
import { StakeholderDividend } from "../../types";
import DividendsTableFooter from "../dividends-table-footer";
import DividendsTableHeader from "../dividends-table-header";
import CalculatedTableBody from "./calculated-table-body";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.body.table");
const tV = createTranslation(TranslationNS.validation);

const validationSchema = Yup.array().of(
  Yup.object({
    dividendAmount: Yup.number()
      .moreThan(0, () => tV("minNumberMore", { number: 0 }))
      .required(() => tV("required")),
    dividendDate: Yup.string().required(() => tV("required")),
  })
);

const CalculatedTable: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const calculatedDividends = useStoreState((state) => state.aroModel.calculatedDividends);
  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);

  const headColumns: HeadColumn[] = useMemo(
    () => [
      {
        key: "shareholder",
        value: t("stakeholder"),
      },
      {
        key: "date",
        value: t("date"),
      },
      {
        key: "dividends",
        value: t("dividends"),
      },
    ],
    []
  );

  const initialValues = useMemo(() => calculatedDividends || [], [calculatedDividends]);

  const handleSubmit = useCallback(
    async (values: StakeholderDividend[]) => {
      if (companyId) {
        const body: PostDividendsBody = {
          companyId: companyId,
          stakeholderDividends: values.map((stakeholder) => ({
            stakeholderId: stakeholder.stakeholderId,
            dividendDate: stakeholder.dividendDate,
            dividendAmount: Number(stakeholder.dividendAmount),
          })),
        };

        const result = await aroService.postDividends(body);

        if (result) {
          notify(t("successToast"), true, "success");
          await getAroDetails(companyId);
        }
      }
    },
    [companyId, getAroDetails]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm }) => {
        return (
          <>
            <CTable
              headComponent={<DividendsTableHeader columns={headColumns} />}
              bodyComponent={<CalculatedTableBody />}
              footComponent={<DividendsTableFooter />}
            />
            <div className={cn(classesBg.backgroundInfo, "d-flex justify-content-between rounded-3 py-2 px-3 mt-3")}>
              <div>
                <Ui.m bold>{t("submitPlate.title")}</Ui.m>
                <P.s>{t("submitPlate.paragraph")}</P.s>
              </div>
              <Button variant="primaryInformation" isLoading={isSubmitting} onClick={submitForm}>
                {t("submitPlate.submit")}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default CalculatedTable;
