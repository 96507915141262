import { useAccordionButton } from "react-bootstrap";

import { P, Tag, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import ProgrammaticAccordionToggle from "pages/equity-management/ownership/transactions/components/transactions-list/programmatic-accordion-toggle/programmatic-accordion-toggle";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { checkAroEnableStep } from "../../aro-toast-warning/aro-toast-warning";
import { StatusTagVariantMapper } from "../../main";
import { AroSteps } from "../../types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.header");

const DividendsHeader = () => {
  const decoratedOnClick = useAccordionButton(AroSteps.Dividends);
  const dividendsStatus = useStoreState((store) => store.aroModel.aroDetails?.dividendsStatus || 0);
  const isAroDetailsLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);
  const year = useStoreState((store) => store.aroModel.aroDetails?.year);
  const aroDetails = useStoreState((store) => store.aroModel.aroDetails);

  const isStepActive =
    aroDetails?.currentShareholdersStatus === AroSectionStatuses.Utfylt ||
    aroDetails?.currentShareholdersStatus === AroSectionStatuses.Forhåndsutfylt;

  return (
    <div
      onClick={checkAroEnableStep(isStepActive, decoratedOnClick)}
      className="d-flex justify-content-between py-2 px-3"
    >
      <div>
        <Ui.xl bold>{t("title")}</Ui.xl>
        <P.s color="foregroundMedium">
          {t.el("subtitle", {
            components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
            values: { year: year || 2000 },
          })}
        </P.s>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Tag variant={StatusTagVariantMapper[dividendsStatus]}>
          <Skeleton isLoading={isAroDetailsLoading}>{AroSectionStatuses[dividendsStatus]}</Skeleton>
        </Tag>
        <ProgrammaticAccordionToggle eventKey={AroSteps.Dividends} itemIdToOpen={null} />
      </div>
    </div>
  );
};

export default DividendsHeader;
