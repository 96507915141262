import { FC, memo, MouseEventHandler, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { defaultTo } from "ramda";

import { Button, NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers/useFormatNumbers";
import { CheckIcon, EditIcon, WarningExclamationMarkIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import StakeholderFormSidebar from "pages/equity-management/ownership/stakeholders-management/components/stakeholder-form-sidebar";
import { useStoreActions, useStoreState } from "store/store";

import { Shareholder } from "../../types";
import TableLoading from "./table-loading";
import { ShareholdersTableKeys, ShareholdersTableSorting } from "./types";

type PT = {
  sort: ShareholdersTableSorting;
  shareholders: Shareholder[];
};

const ShareholdersTableBody: FC<PT> = memo(({ sort, shareholders }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const fNumber = useFormatNumbers();

  const [shareholderIdToEdit, setShareholderIdToEdit] = useState<number | undefined>();
  const isLoading = useStoreState((store) => store.aroModel.isShareholdersAtYearEndLoading);
  const countries = useStoreState((store) => store.common.dropdowns?.countries || []);
  const getShareholderAtYearEnd = useStoreActions((store) => store.aroModel.getShareholdersAtYearEndThunk);

  const sortedShareholders = useMemo(() => {
    return shareholders.sort((a, b) => {
      if (sort.field === ShareholdersTableKeys.missingInfo) {
        if (sort.sortDirection === sortingParams.inc) {
          return a.missingInformation > b.missingInformation ? 1 : -1;
        }

        return a.missingInformation > b.missingInformation ? -1 : 1;
      }

      return 1;
    });
  }, [shareholders, sort.field, sort.sortDirection]);

  const handleEditClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    setShareholderIdToEdit(Number(e.currentTarget.id));
  }, []);

  return (
    <tbody>
      {isLoading ? (
        <TableLoading />
      ) : (
        sortedShareholders.map((shareholder) => (
          <tr key={shareholder.stakeholderId}>
            <td>
              <div className="d-flex align-items-center">
                <NewAvatar
                  size="m"
                  imageUrl={defaultTo(undefined, shareholder?.avatarFilePath)}
                  company={shareholder.isCompanyOwned}
                  initials={shareholder.initials}
                />

                <div className="ms-2">
                  <div className="d-flex align-items-center">
                    <Ui.s bold className="me-1">
                      {shareholder.shareholderName || "-"}
                    </Ui.s>
                  </div>

                  {shareholder.isCompanyOwned ? (
                    <Ui.s style={{ color: scssVariables.foregroundLow }}>{shareholder.representedBy}</Ui.s>
                  ) : null}
                </div>
              </div>
            </td>
            <td>
              <Ui.s>{shareholder.isin || "-"}</Ui.s>
            </td>
            <td>
              <Ui.s>{shareholder.address || "-"}</Ui.s>
            </td>
            <td>
              <Ui.s>{shareholder.postalCode || "-"}</Ui.s>
            </td>
            <td>
              <Ui.s>{shareholder.postalCity || "-"}</Ui.s>
            </td>
            <td>
              <Ui.s>{countries.find((country) => country.id === shareholder?.countryId)?.name || "-"}</Ui.s>
            </td>
            <td>
              <Ui.s>{shareholder.shareClassName}</Ui.s>
            </td>
            <td className="text-end">
              <Ui.s>{fNumber(shareholder.numberOfShares, "amount")}</Ui.s>
            </td>
            <td>
              <div className="d-flex justify-content-center align-items-center ps-2">
                {shareholder.missingInformation ? (
                  <WarningExclamationMarkIcon fontSize={24} color={scssVariables.information700} />
                ) : (
                  <CheckIcon fontSize={24} color={scssVariables.positive500} />
                )}
                <Button
                  isOnlyIcon
                  size="s"
                  variant="tertiary"
                  className="ms-auto"
                  id={String(shareholder.stakeholderId)}
                  onClick={handleEditClick}
                >
                  <EditIcon />
                </Button>
              </div>
            </td>
          </tr>
        ))
      )}
      <StakeholderFormSidebar
        stakeholderId={shareholderIdToEdit}
        onClose={() => setShareholderIdToEdit(undefined)}
        isOpen={!!shareholderIdToEdit}
        onCreateSuccess={() => getShareholderAtYearEnd(companyId)}
        isAroFlow
      />
    </tbody>
  );
});

export default ShareholdersTableBody;
