import { FC } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useFormikContext } from "formik";

import { Button, LoaderContainer, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { ArrowLeftIcon } from "common/icons/svg";
import WizardLayoutStore from "common/layout/WizardLayout/store";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CloseConfirm from "../../common/close-confirm";
import { PostFormTransaction } from "../types";
import classes from "./styles.module.scss";
import Table from "./table";
import { createInitValues } from "./use-edit-transactions-form";

type PT = {
  columns: ColumnDef<PostFormTransaction>[];
  onClose(): void;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.editTransactionsTable");

const Form: FC<PT> = ({ columns, onClose }) => {
  const year = useStoreState((state) => state.aroModel?.aroDetails?.year || 2000);
  const isLoading = useStoreState((state) => state.aroModel?.isFormTransactionsLoading);
  const setCloseConfirmOpenAction = WizardLayoutStore.useStoreActions((actions) => actions.setCloseConfirmOpen);
  const { isSubmitting, submitForm } = useFormikContext();

  return (
    <>
      <WizardLayout.Header title={t("title", { year })} />
      <div className="d-flex justify-content-between align-items-center px-7">
        <Button
          onClick={() => setCloseConfirmOpenAction(true)}
          isOnlyIcon
          variant="secondary"
          isDisabled={isSubmitting}
        >
          <ArrowLeftIcon />
        </Button>
        <Ui.l color="foregroundMedium">
          {t.el("paragraph", {
            components: [<Skeleton isLoading={isLoading} key={0} />],
            values: {
              dateFrom: `01.01.${year}`,
              dateTo: `31.12.${year}`,
            },
          })}
        </Ui.l>
        <Button onClick={submitForm} isLoading={isSubmitting}>
          {t("submitBtn")}
        </Button>
      </div>
      <WizardContent hideSteps className={classes.maxWidth}>
        <div className={classes.wrap}>
          <LoaderContainer loading={isLoading}>
            <Table columns={columns} createInitialItems={createInitValues} />
          </LoaderContainer>
        </div>
      </WizardContent>
      <CloseConfirm handleClose={onClose} />
    </>
  );
};

export default Form;
