import { ChangeEventHandler, FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Button, ChecksGroup, H, P, Ui } from "common/components/atoms";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { DividendsModes } from "../types";

type PT = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.dividends.body.radiobuttons");

const RadiobuttonsSection: FC<PT> = ({ className }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const [isShowWarningModal, setIsShowWarningModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const year = useStoreState((state) => state.aroModel.aroDetails?.year || 2000);
  const dividendMode = useStoreState((state) => state.aroModel.dividends?.dividendMode);
  const dividendsStatus = useStoreState((state) => state.aroModel.aroDetails?.dividendsStatus);
  const isLoading = useStoreState((state) => state.aroModel.isDividendsLoading);
  const shareClasses = useStoreState((state) => state.aroModel.shareholdersAtYearEnd?.shareClasses || []);
  const postDividendsModeThunk = useStoreActions((actions) => actions.aroModel.postDividendsModeThunk);
  const getShareholdersAtYear = useStoreActions((actions) => actions.aroModel.getShareholdersAtYearEndThunk);

  useEffect(() => {
    if (companyId) {
      getShareholdersAtYear(companyId);
    }
  }, [companyId, getShareholdersAtYear]);

  const handleRadioClick: ChangeEventHandler<HTMLInputElement> = async ({ target }) => {
    if (companyId) {
      if (
        (Number(target.name) as unknown as DividendsModes) === DividendsModes.NoDividend &&
        dividendsStatus === AroSectionStatuses.Utfylt
      ) {
        setIsShowWarningModal(true);
      } else {
        await postDividendsModeThunk({ companyId, dividendMode: Number(target.name) as unknown as DividendsModes });
      }
    }
  };

  const deleteDividends = async () => {
    if (companyId) {
      setIsDeleting(true);
      await postDividendsModeThunk({ companyId, dividendMode: DividendsModes.NoDividend });
      setIsShowWarningModal(false);
      setIsDeleting(false);
    }
  };

  return (
    <div className={className}>
      <Ui.m bold className="mb-1">
        {t("title")}
      </Ui.m>
      <ChecksGroup>
        <ChecksGroup.Check
          type="radio"
          name={String(DividendsModes.NoDividend)}
          disabled={isLoading}
          checked={dividendMode === DividendsModes.NoDividend}
          onChange={handleRadioClick}
          label={t("option1.label")}
          description={t("option1.description", { year })}
        />
        <ChecksGroup.Check
          type="radio"
          name={String(DividendsModes.AutomaticDividend)}
          disabled={isLoading || shareClasses?.length > 1}
          checked={dividendMode === DividendsModes.AutomaticDividend}
          onChange={handleRadioClick}
          label={t("option2.label")}
          description={t("option2.description")}
        />
        <ChecksGroup.Check
          type="radio"
          name={String(DividendsModes.ManualDividend)}
          disabled={isLoading}
          checked={dividendMode === DividendsModes.ManualDividend}
          onChange={handleRadioClick}
          label={t("option3.label")}
          description={t("option3.description")}
        />
      </ChecksGroup>

      <Modal show={isShowWarningModal} centered onHide={() => setIsShowWarningModal(false)}>
        <div className="d-flex flex-column text-center p-5">
          <H.xs className="mb-3">{t("deleteModal.title", { year })}</H.xs>
          <P.m className="mb-5">{t("deleteModal.paragraph", { year })}</P.m>
          <div>
            <Button className="me-2" onClick={deleteDividends} isLoading={isDeleting}>
              {t("deleteModal.submit")}
            </Button>
            <Button variant="secondary" onClick={() => setIsShowWarningModal(false)} isDisabled={isDeleting}>
              {t("deleteModal.cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RadiobuttonsSection;
