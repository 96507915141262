import { FC, useCallback } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { createTranslation, TranslationNS } from "../../../../translation";
import { Button, P, Ui } from "../../../components/atoms";
import useSubscriptionType from "../../../hooks/useSubscriptionType";
import lockImage from "../../../icons/lock.png";
import { CONTACT_US_LINK, VIEW_PRICES_LINK } from "../../../utils/constants";
import { FEATURES } from "../../types";
import { useFeatures } from "../../useFeatures";
import classes from "../NoSubscription.module.scss";

const t = createTranslation(TranslationNS.common, "noSubscription");

type Props = {};
const DefaultNoSubscriptionTemplate: FC<Props> = () => {
  const { hasFullAccess } = useFeatures(FEATURES.companyInformation);

  const { hasSubscriptionType } = useSubscriptionType();

  const handleScroll = useCallback(() => {
    document.getElementById("stripeManagePlan")?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, []);

  return (
    <>
      <div className={classes.container}>
        <div className="d-flex justify-content-center">
          <div>
            {hasSubscriptionType ? (
              <Ui.xl bold className="mb-3">
                {t("title")}
              </Ui.xl>
            ) : (
              <Ui.xl bold className="mb-3">
                {t("titleNoSubscription")}
              </Ui.xl>
            )}

            <P.m>{t("description")}</P.m>

            <P.m className="mt-4">
              {t.el("curiousNoSubscriptionText", {
                components: [
                  <Link key={1} to={VIEW_PRICES_LINK} className={classes["link"]} target="_blank" rel="noreferrer" />,
                ],
              })}
            </P.m>
          </div>

          <Image className={classNames("ms-5", classes.image)} alt="lock image" src={lockImage} />
        </div>

        <div className={classNames("mt-5", classes["actions"])}>
          {hasFullAccess && (
            <Button size="s" className="me-2" onClick={handleScroll}>
              View details
            </Button>
          )}

          <Button as="a" size="s" target="_blank" rel="noreferrer" variant="secondary" href={CONTACT_US_LINK}>
            {t("contactUs")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default DefaultNoSubscriptionTemplate;
