import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { isNil } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { Button, Dropdown, H, P, Ui } from "common/components/atoms";
import { OnboardingCompanySearch } from "common/components/molecules";
import { ChevronRightIcon, CloseIcon } from "common/icons/svg";
import { CONTACT_US_LINK, scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import Img from "../../../onboard/company/assets/card-img-1.png";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "onboard.company.aroOnboarding");

const AroOnboarding = () => {
  const navigate = useNavigate();

  const user = useStoreState((state) => state.account.user);
  const setAroOnboarding = useStoreActions((actions) => actions.companyOnboardingModel.setAroOnboarding);
  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companiesList, setCompaniesList] = useState<{ id: number; name: string }[]>([]);
  const [isAdminDropdownSelectionEnabled, setIsAdminDropdownSelectionEnabled] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<Partial<string>>();

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCompany(e.target.value);
  }, []);

  const getCompaniesList = useCallback(async () => {
    try {
      const request = await axios.get("/api/company/where-admin");

      if (request.status === 200) {
        setCompaniesList(request.data.companies);
      }
    } catch (e) {
      console.error({ e });
    }
  }, []);

  const createCompanyHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      const request = await axios.post("/api/aro/create-company", {
        organizationNumber: selectedCompany,
      });

      if (request.status === 200) {
        await getAccountThunk();

        setAroOnboarding({
          isActive: false,
        });

        navigate(getPath(["aro", "report"], { companyId: request.data.companyId }));
      }
    } catch (e) {
      console.error({ e });
    } finally {
      setIsLoading(false);
    }
  }, [getAccountThunk, navigate, selectedCompany, setAroOnboarding]);

  const submitHandler = async () => {
    if (isAdminDropdownSelectionEnabled) {
      setAroOnboarding({
        isActive: false,
      });

      navigate(getPath(["aro", "report"], { companyId: selectedCompany }));
      return;
    }

    await createCompanyHandler();
  };

  const handleClose = () => {
    if (user?.companies?.length) {
      setAroOnboarding({
        isActive: false,
      });

      navigate(getPath(["user", "dashboard"]));

      return;
    }
  };

  useEffect(() => {
    getCompaniesList();
  }, [getCompaniesList]);

  useEffect(() => {
    if (companiesList.length) {
      setIsAdminDropdownSelectionEnabled(true);
    }
  }, [companiesList.length]);

  return (
    <div className={classes["wrap"]}>
      <div className="px-6">
        <H.s className="mt-7">{t("title")}</H.s>

        <P.l className="mt-2 mb-6" color="foregroundMedium">
          {isAdminDropdownSelectionEnabled
            ? t("manageCompanies", {
                amount: companiesList.length,
              })
            : t("description")}
        </P.l>

        {isAdminDropdownSelectionEnabled ? (
          <Dropdown
            isSearchable
            optionsIsObject
            options={companiesList}
            label={t("chooseCompany")}
            selectedValue={companiesList?.find((el) => String(el.id) === selectedCompany)?.id}
            onChange={(cmp) => {
              setSelectedCompany(String(cmp.target.value));
            }}
          />
        ) : (
          <OnboardingCompanySearch onChange={handleChange} selectedValue={String(selectedCompany)} />
        )}

        <Ui.s className="mt-3" color="foregroundMedium">
          {isAdminDropdownSelectionEnabled
            ? t.el("submitShareholderRegister", {
                components: [
                  <Link
                    to="#"
                    key="1"
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                    onClick={() => {
                      setIsAdminDropdownSelectionEnabled(false);
                    }}
                  />,
                ],
              })
            : companiesList.length
            ? t.el("companyExist", {
                components: [
                  <Link
                    to="#"
                    key="1"
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                    onClick={() => {
                      setIsAdminDropdownSelectionEnabled(true);
                    }}
                  />,
                  <Link
                    key="2"
                    target="_blank"
                    to={CONTACT_US_LINK}
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                  />,
                ],
              })
            : t.el("cantFindCompany", {
                components: [
                  <Link
                    key="3"
                    target="_blank"
                    to={CONTACT_US_LINK}
                    style={{ color: scssVariables.primary1, textDecoration: "underline" }}
                  />,
                ],
              })}
        </Ui.s>

        <Button
          className="mt-3 mb-7"
          isLoading={isLoading}
          isDisabled={isLoading || isNil(selectedCompany)}
          iconRight={<ChevronRightIcon />}
          onClick={submitHandler}
        >
          {t("continue")}
        </Button>
      </div>

      <div className={classes["img-wrap"]}>
        <Image src={Img} />
      </div>

      <Button isOnlyIcon className={classes["close-btn"]} variant="tertiary" onClick={handleClose}>
        <CloseIcon fontSize={32} />
      </Button>
    </div>
  );
};

export default AroOnboarding;
